// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  labReportApi: 'https://stagging.labreport.wakanda.reliancehmo.com/api',
  pdf2HtmlApi: 'https://stagging.pdf2html-smartrfc.wakanda.reliancehmo.com',
  // pdf2HtmlApi: 'http://localhost:3000',
  apiUrl: 'https://stagging.api-smartrfc.wakanda.reliancehmo.com',
  // apiUrl: 'http://localhost:4000',
  s3Region: 'us-west-2',
  s3_access_id: 'AKIA2R36NM4ZANNY7RWU',
  s3_secret: '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',
  s3_bucket: 'smart-rfc-labs-test',
  admin_username: 'admin',
  admin_password: 'labadmin2020'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
