import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Store } from 'src/app/core/store/store';
import { Observable } from 'rxjs';

const PAGE_SIZE = 8;
const DEFAULT_STATE: KeyState = {
  loaded: false,
  loading: false,
  error: false,
  currentPage: 0,
  data: [],
};

@Injectable({
  providedIn: 'root',
})
export class KeyService extends Store<KeyState> {
  currentState: KeyState;
  constructor(http: HttpClient) {
    super(http, DEFAULT_STATE);
    this.getState().subscribe((state) => (this.currentState = state));
  }

  saveKey(key: IKey): void {
    this.setState({
      ...this.currentState,
      data: [...this.currentState.data, key],
    });
  }

  updateKeyFromState(key: IKey): void {
    const previousState = [...this.currentState.data].filter(vkey => vkey._id !== key._id);
    this.setState({
      ...this.currentState,
      data: [...previousState, key]
    });
  }

  deleteKeyFromState(key: IKey): void {
    const previousState = [...this.currentState.data].filter(vkey => vkey._id !== key._id);
    this.setState({
      ...this.currentState,
      data: [...previousState]
    });
  }

  loadKeys(): void {
    if (this.currentState.loaded || this.currentState.loading) {
      return;
    }
    const currentPage = this.currentState.currentPage + 1;
    this.setState({
      ...this.currentState,
      loading: true,
      currentPage,
    });
    this.get<Array<IKey>>(`/keys`).subscribe(
      (keys) => {
        // const bKeys = [...keys];
        // bKeys.map(key => this.getKeyCategory(key.category).subscribe(data => key.category = data));
        this.setState({
          ...this.currentState,
          data: [...this.currentState.data, ...keys],
          loaded: true,
          loading: false,
        });
      },
      (err) => {
        this.setState({
          ...this.currentState,
          error: true,
          loading: false,
          loaded: false,
        });
      },
    );
  }

  addCategoryKey(payload: ICategoryKey): Observable<ICategoryKey> {
    return this.post<ICategoryKey>(payload, '/category-keys');
  }

  updateCategoryKey(
    payload: ICategoryKey,
    id: string,
  ): Observable<ICategoryKey> {
    return this.put<ICategoryKey>(payload, `/category-keys/${id}`);
  }

  getCategoryKeys(): Observable<Array<ICategoryKey>> {
    return this.get<Array<ICategoryKey>>('/category-keys');
  }

  getKeysOfCategory(category: string): Observable<Array<IKey>> {
    return this.get<Array<IKey>>(`/keys/category/${category}`);
  }

  deleteCategoryKey(id: string): Observable<ICategoryKey> {
    return this.delete<ICategoryKey>(`/category-keys/${id}`);
  }

  addKey(payload: IKey): Observable<IKey> {
    return this.post<IKey>(payload, '/keys');
  }

  updateKey(payload: IKey, id: string): Observable<IKey> {
    return this.put<IKey>(payload, `/keys/${id}`);
  }

  deleteKey(id: string): Observable<IKey> {
    return this.delete<IKey>(`/keys/${id}`);
  }

  getKeyCategory(id: string): Observable<ICategoryKey> {
    return this.get<ICategoryKey>(`/category-keys/${id}`);
  }
}

export interface ICategoryKey {
  name: string;
  description: string;
  createdAt?: any;
  _id?: any;
  id?: any;
}

export interface IKey {
  label: string;
  description: string;
  createdAt?: any;
  _id?: any;
  id?: any;
  category: any;
  deletable?: string;
}

export interface KeyState {
  loaded: boolean;
  loading: boolean;
  error: boolean;
  currentPage: number;
  data: Array<IKey>;
}
