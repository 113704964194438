import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/core/providers/auth.service';
import { ToastService } from 'src/app/core/providers/toast.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass'],
})
export class LoginComponent implements OnInit {
  payload = {
    username: '',
    password: '',
  };
  constructor(public authService: AuthService, private router: Router, private toastService: ToastService) {}

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/dashboard/home']);
    }
  }

  login(event: any): void {
    event.preventDefault();
    this.authService.login(this.payload).then(
      response => {
        if (response) {
          this.router.navigate(['/dashboard/home']);
        } else {
          this.toastService.notify('error', 'Invalid credentials, wrong Username or Password !');
        }
      }
      ).catch(
        error => {
          console.log(error);
          this.toastService.notify('error', 'Invalid credentials, wrong Username or Password !');
      }
    );
  }
}
