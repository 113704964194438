import { Injectable } from '@angular/core';
import { IDocument } from './document.service';
import { HttpClient } from '@angular/common/http';
import { Store } from 'src/app/core/store/store';
import { Observable } from 'rxjs';

const PAGE_SIZE = 8;
const DEFAULT_STATE: DocumentPageState = {
  loaded: false,
  loading: false,
  error: false,
  currentPage: 0,
  data: [],
};

@Injectable({
  providedIn: 'root'
})
export class DocumentPagesService extends Store<DocumentPageState> {
  currentState: DocumentPageState;
  constructor(http: HttpClient) {
    super(http, DEFAULT_STATE);
    this.getState().subscribe((state) => (this.currentState = state));
  }

  saveDocumentPage(documentPage: IDocumentPage): void {
    this.setState({
      ...this.currentState,
      data: [...this.currentState.data, documentPage],
    });
  }

  updateDocumentPageFromState(documentPage: IDocumentPage): void {
    const previousState = [...this.currentState.data].filter(page => page._id !== documentPage._id);
    this.setState({
      ...this.currentState,
      data: [...previousState, documentPage]
    });
  }

  deleteDocumentPageFromState(documentPage: IDocumentPage): void {
    const previousState = [...this.currentState.data].filter(page => page._id !== documentPage._id);
    this.setState({
      ...this.currentState,
      data: [...previousState]
    });
  }

  loadDocumentPages(): void {
    if (this.currentState.loaded || this.currentState.loading) {
      return;
    }
    const currentPage = this.currentState.currentPage + 1;
    this.setState({
      ...this.currentState,
      loading: true,
      currentPage,
    });
    this.get<Array<IDocumentPage>>(`/document-pages`).subscribe(
      (documentPages) => {
        this.setState({
          ...this.currentState,
          data: [...this.currentState.data, ...documentPages],
          loaded: true,
          loading: false,
        });
      },
      (err) => {
        this.setState({
          ...this.currentState,
          error: true,
          loading: false,
          loaded: false,
        });
      },
    );
  }

  deleteDocumentPage(id: string): Observable<IDocumentPage> {
    return this.delete<IDocumentPage>(`/document-pages/${id}`);
  }

  getDocumentPage(id: string): Observable<IDocumentPage> {
    return this.get<IDocumentPage>(`/document-pages/${id}`);
  }

  updateDocumentPage(id: string, payload: { html: string }): Observable<IDocumentPage> {
    return this.put<IDocumentPage>(payload, `/document-pages/update-html/${id}`);
  }

}

export interface IDocumentPage {
  document?: IDocument;
  url?: {
    Location: string;
    ETag: string;
    Key: string;
  };
  html?: string;
  createdAt?: Date;
  _id?: any;
}

export interface DocumentPageState {
  loaded: boolean;
  loading: boolean;
  error: boolean;
  currentPage: number;
  data: Array<IDocumentPage>;
}
