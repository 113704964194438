import {
  Component,
  OnInit,
  Input,
  Output,
  ChangeDetectorRef,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  AbstractControl,
  Validators,
} from '@angular/forms';
import { Subject, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.sass'],
})
export class FormComponent implements OnInit {
  @Input() inputs: Array<IInputsType> = [];
  @Input() formClass = '';
  @Input() inputWrapperClass = '';
  @Input() actionsWrapperClass = '';
  @Input() actions = [];
  @Input() submitLoading: boolean;
  @Input() resetForm$: Subject<any>;

  @Output() actionPerformed$ = new Subject<{ id: string; form: FormGroup }>();

  form: FormGroup;
  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setForms({
      resetEmpty: false,
    });
    if (this.resetForm$) {
      this.resetForm$.subscribe((inputs) => {
        if (!inputs) {
          inputs = this.inputs.reduce((previous, input) => {
            return {
              ...previous,
              [input.id]: '',
            };
          }, {});
        }
        this.setValue(inputs);
      });
    }
  }

  setForms(config: { resetEmpty?: boolean }): void {
    const controlsConfig = this.inputs.reduce((previous, input) => {
      return {
        ...previous,
        [input.id]: [config.resetEmpty ? '' : input.value, input.validators],
      };
    }, {});
    this.form = this.fb.group(controlsConfig);
  }

  setValue(inputs): void {
    this.form.setValue(inputs);
    this.form.clearValidators();
  }

  actionsPerformed(id: string): void {
    this.actionPerformed$.next({
      id,
      form: this.form,
    });
  }

  getInputForm(inputName: string): AbstractControl {
    return this.form.get(inputName);
  }

  onFileChange(event: any, input: IInputsType): void {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        this.form.patchValue({
          [input.id]: file,
        });
        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };
    }
  }
}

export interface IInputsType {
  groupClass: string;
  labelClass: string;
  inputClass: string;
  inputClassSuccess: string;
  inputClassError: string;
  helperClass: string;
  type: string;
  form: string;
  id: any;
  value: any;
  label: string;
  placeholder: string;
  helperText: string;
  validators: Array<Validators>;
  selectOptionValues$: BehaviorSubject<{ label: string; value: any }>;
  accept?: any;
}
