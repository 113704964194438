<nav class="flex items-center justify-between flex-wrap bg-white-700 p-6">
  <div class="flex items-center flex-shrink-0 text-white mr-6">
    <img src="assets/images/logo_baamtu.png" alt="" />
  </div>
  <div class="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
    <div class="text-sm lg:flex-grow">
      <!-- <div class="inline-flex items-center ml-8">
        <a
          *ngIf="!(this.slideToDocument('prev') | async)"
          href="{{
            '/annotation/' + (this.slideToDocument('prev') | async)?._id
          }}"
          class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l"
        >
          Prev
        </a>
        <a
          *ngIf="!(this.slideToDocument('next') | async)"
          [href]="'/annotation/' + (this.slideToDocument('next') | async)?._id"
          class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r"
        >
          Next
        </a>
      </div> -->
      <select
        name="hasStructuredTable"
        id="hasStructuredTable"
        class="block appearance-none w-2/4 bg-gray-200 border-4 border-blue-600 border-opacity-50 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        id="grid-state"
        [class.border-red-400]="this.hasStructuredTableError"
        [(ngModel)]="this.hasStructuredTable"
      >
        <option *ngFor="let option of options" [value]="option.value">{{ option.label }}</option>
      </select>
      <button
        (click)="this.saveAnnotations()"
        [disabled]="this.savePending"
        class="bg-blue-500 hover:bg-blue-700 text-white mt-2 font-bold py-2 px-4 rounded ml-1 btn-sm"
        [class.bg-gray-500]="this.savePending"
      >
        Save annotations
      </button>
      
      <!-- <button
      (click)="this.toggleTableColumnAnnotationsModal()"
      [hidden]="!this.hasStructuredTable"
      class="bg-purple-700 hover:bg-gray-700 text-white mt-2 font-bold py-2 px-4 rounded ml-1 btn-sm"
      [class.bg-gray-500]="this.savePending"
    >
      Annotate table columns
    </button>

    <app-regular-modal
      #modal
      [parameters]="this.tableColumnAnnotationsModalParams"
      [showModal]="this.showTableColumnAnnotationsModal"
      (showModalStateChange)="this.updateModalState($event)"
      (onSaveAnnotatedTableColumns)="this.saveTableColumnAnnotations($event)"
    >
  </app-regular-modal> -->

      <img
        *ngIf="this.savePending"
        src="assets/images/Spinner-1s-200px.gif"
        class="w-12 h-12"
      />
    </div>
    
    <div class="instructions">
      <h4 class="text-center"><b><i><u>Annotation instructions (⚠️To be done for each page)</u></i></b></h4>
      <ul>
        <li>1- Associate all relevant information outside tables to a key</li>
        <li>2- Answer the annotation completed question</li>
        <li>3- Save your annotations</li>
      </ul>
    </div>

    <div>
      <a
        routerLink="/dashboard/documents"
        class="inline-flex items-center text-sm px-4 py-2 leading-none border-2 border-blue-500 rounded text-black bg-transparent hover:bg-blue-500 hover:text-white hover:bg-black mt-4 lg:mt-0"
      >
        <svg
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          fill-rule="evenodd"
          clip-rule="evenodd"
          class="hover:color-white"
        >
          <path
            d="M20 .755l-14.374 11.245 14.374 11.219-.619.781-15.381-12 15.391-12 .609.755z"
          />
        </svg>
        <span><b>Back to documents</b></span>
      </a>
    </div>
  </div>


  
</nav>
<div class="bg-white py-2 mx-2">
  <div class="flex m-2">
    <div class="w-full">
      <h2 class="mb-2"><b><i>Personnal Information Keys</i></b></h2>
      <button
        *ngFor="let key of this.keys$ | async"
        (click)="onSelectedKey($event, key)"
        [hidden]="key.category === 'test_information'"
        class="bg-{{ key.color }}-500 hover:bg-{{
          key.color
        }}-700 text-white font-bold py-2 px-4 rounded ml-1 btn-sm"
      >
        {{ key.label }}
      </button>
    </div>
  </div>
</div>
<div class="bg-white py-2 mx-2">
  <div class="flex m-2">
    <div class="w-full">
      <h2 class="mb-2"><b><i>Test Information Keys</i></b></h2>
      <button
        *ngFor="let key of this.keys$ | async"
        (click)="onSelectedKey($event, key)"
        [hidden]="key.category === 'personnal_information'"
        class="bg-{{ key.color }}-500 hover:bg-{{
          key.color
        }}-700 text-white font-bold py-2 px-4 rounded ml-1 btn-sm"
      >
        {{ key.label }}
      </button>
    </div>
  </div>
</div>
<div class="mx-2 bg-white">
  <div class="flex mb-4">
    <div class="w-full">
      <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
        <label
          class="block tracking-wide text-gray-700 font-bold mb-2"
          for="file"
        >
          {{ (this.currentDocument$ | async)?.idDocument }}
        </label>
      </div>
    </div>
  </div>
  <div class="navigation-buttons">
    <button
      class="bg-yellow-400 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded ml-1 btn-sm"
      (click)="this.navigateBetweenDocumentPage('previous')"
      [disabled]="this.currentPage === 0"
      [class]="{'bg-yellow-800': this.currentPage === 0}"
      >
      Previous
    </button>
    <button
      class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ml-1 btn-sm"
      (click)="navigateBetweenDocumentPage('next')"
      [disabled]="this.currentPage === this.document?.pages?.length - 1"
      [class]="{'bg-green-800': this.currentPage === this.document?.pages?.length - 1}"
      >
      Next
    </button>
  </div>
      <img
        *ngIf="this.isAnnotationLoading"
        src="assets/images/loader.gif"
        class="w-22 h-22 img-center"
      />
  <div class="flex mb-4">
    <div class="w-full">
      <div (mouseup)="this.textSelected($event)" id="content" class="p-8">
        <div id="pdf-extract"></div>
      </div>
    </div>
  </div>
</div>
