import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { ToastService } from 'src/app/core/providers/toast.service';
import { KeyService, ICategoryKey } from 'src/app/core/providers/key.service';

@Component({
  selector: 'app-category-key',
  templateUrl: './category-key.component.html',
  styleUrls: ['./category-key.component.sass'],
})
export class CategoryKeyComponent implements OnInit {
  formClass = 'bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4';
  inputWrapperClass = 'grid grid-cols-2 gap-4';
  actionsWrapperClass = 'flex items-center justify-between';
  inputs = [];
  formActions = [];
  submitLoading = false;
  resetForm$ = new Subject<ICategoryKey>();

  categoryKeys$ = new BehaviorSubject<Array<ICategoryKey>>([]);
  selectedCategory: ICategoryKey;

  modalParameters = {
    title: '',
    message: '',
    operation: '',
    isOpen: false
  };

  formValue;
  constructor(public keyService: KeyService, private toastService: ToastService) {}

  ngOnInit(): void {
    this.setForm();
    this.keyService.getCategoryKeys().subscribe((res) => {
      this.categoryKeys$.next(res);
    });
  }

  setForm(): void {
    this.formActions = [
      {
        type: 'button',
        id: 'submit',
        class:
          'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline',
        errorClass: 'bg-gray-500 text-white font-bold py-2 px-4 rounded',
        text: 'Save',
        validationRequired: true,
      },
    ];
    this.inputs = [
      {
        groupClass: 'col-span-12 mb-4',
        labelClass: 'block text-gray-700 text-sm font-bold mb-2',
        inputClass:
          'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
        inputClassSuccess: 'border-green-500',
        inputClassError: 'border-red-500',
        helperClass: '',
        type: 'text',
        form: 'input',
        id: 'name',
        value: '',
        label: 'Name',
        placeholder: 'Name',
        helperText: '',
        validators: [Validators.required],
      },
      {
        groupClass: 'col-span-12 mb-4',
        labelClass: 'block text-gray-700 text-sm font-bold mb-2',
        inputClass:
          'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
        inputClassError: 'border-red-500',
        helperClass: '',
        type: 'text',
        form: 'textArea',
        id: 'description',
        value: '',
        label: 'Description',
        placeholder: 'Description',
        helperText: '',
        validators: [Validators.required],
      },
    ];
  }

  onActionPerformed($event: { id: string; form: FormGroup }): void {
    switch ($event.id) {
      case 'submit':
        if ($event.form.valid) {
          this.addCategoryKey($event.form.value);
        }
        break;
      case 'cancel':
        this.resetForm$.next();
        this.setForm();
        break;
      case 'update':
        if ($event.form.valid) {
          this.modalParameters.title = 'Update Key Category';
          this.modalParameters.message = 'Are you sure you want to update this key category ?';
          this.modalParameters.operation = 'update';
          this.modalParameters.isOpen = true;
          this.formValue = $event.form.value;
        }
        break;
    }
  }

  addCategoryKey(payload: ICategoryKey): void {
    this.submitLoading = true;
    payload.createdAt = new Date();
    this.keyService.addCategoryKey(payload).subscribe((res) => {
      this.submitLoading = false;
      this.categoryKeys$.next([...this.categoryKeys$.value, res]);
      this.resetForm$.next();
    });
    this.toastService.notify('success', 'Category Key successfully added.');
  }

  updateCategoryKey(payload: ICategoryKey): void {
    this.submitLoading = true;
    this.keyService
      .updateCategoryKey(payload, this.selectedCategory._id)
      .subscribe((res) => {
        this.submitLoading = false;
        const previousState = [...this.categoryKeys$.value].filter(category => category._id !== res._id);
        this.resetForm$.next();
        this.formValue.createdAt = res.createdAt;
        this.formValue._id = res._id;
        this.categoryKeys$.next([...previousState, this.formValue]);
        this.resetForm$.next();
        this.setForm();
      });
  }

  updateCategoryKeyForm(categoryKey: ICategoryKey): void {
    this.selectedCategory = categoryKey;
    this.resetForm$.next({
      name: categoryKey.name,
      description: categoryKey.description,
    });
    this.formActions = [
      {
        type: 'button',
        id: 'update',
        class:
          'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline',
        errorClass: 'bg-gray-500 text-white font-bold py-2 px-4 rounded',
        text: 'Update',
        validationRequired: true,
      },
      {
        type: 'button',
        id: 'cancel',
        class:
          'bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline',
        errorClass: 'bg-gray-500 text-white font-bold py-2 px-4 rounded',
        text: 'Cancel',
        validationRequired: false,
      },
    ];
    document.getElementById('form-title').scrollIntoView({behavior: 'smooth'});
  }

  deleteCategoryKey(categoryKey: ICategoryKey): void {
    this.modalParameters.title = 'Delete Key Category';
    this.modalParameters.message = 'Are you sure you want to delete this key category ?';
    this.modalParameters.operation = 'delete';
    this.modalParameters.isOpen = true;
    this.formValue = categoryKey;
  }

  manageOperation(emittedValue): void {
    if (emittedValue.proceed) {
      switch (emittedValue.operation) {
        case 'update':
          this.updateCategoryKey(this.formValue);
          this.toastService.notify('warning', 'Key successfully updated !');
          break;
        case 'delete':
          this.keyService.deleteCategoryKey(this.formValue._id).subscribe((res) => {
            const previousState = [...this.categoryKeys$.value].filter(category => category._id !== this.formValue._id);
            this.categoryKeys$.next([...previousState]);
            this.toastService.notify('warning', 'Key successfully deleted !');
          });
          break;
      }
    }
    this.modalParameters.isOpen = false;
  }
}
