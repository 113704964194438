<div class="bg-gray-900 font-sans leading-normal tracking-normal mt-12">
  <!--Nav-->
  <nav
    class="bg-gray-900 pt-2 md:pt-1 pb-1 px-1 mt-0 h-auto fixed w-full z-20 top-0"
  >
    <div class="flex flex-wrap items-center">
      <div
        class="flex flex-shrink md:w-1/3 justify-center md:justify-start text-white"
      >
        <a routerLink="/dashboard">
          <span class="text-xl pl-2">Smart RFC</span>
        </a>
      </div>

      <div
        class="flex flex-1 md:w-1/3 justify-center md:justify-start text-white px-2"
      >
        <span class="relative w-full">
          <input
            type="search"
            placeholder="Search"
            class="w-full bg-gray-800 text-sm text-white transition border border-transparent focus:outline-none focus:border-gray-700 rounded py-1 px-2 pl-10 appearance-none leading-normal"
          />
          <div class="absolute search-icon" style="top: 0.5rem; left: 0.8rem;">
            <svg
              class="fill-current pointer-events-none text-white w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"
              ></path>
            </svg>
          </div>
        </span>
      </div>

      <div
        class="flex w-full pt-2 content-center justify-between md:w-1/3 md:justify-end"
      >
        <ul
          class="list-reset flex justify-between flex-1 md:flex-none items-center"
        >
          <li class="flex-1 md:flex-none md:mr-3">
            <a class="inline-block py-2 px-4 text-white no-underline">
              <i class="fas fa-user fa-x fa-inverse"></i>
                {{ user.role === 0 || user.role === 'ADMIN' ? 'Admin' : 'User' }} - {{ user.username }}
            </a>
          </li>
          <li class="flex-1 md:flex-none md:mr-3">
            <div class="relative inline-block">
              <button
                class="drop-button text-white focus:outline-none"
                title="Logout"
                (click)="this.authService.logout()"
              >
                <svg
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                  class="w-8 h-8"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                  ></path>
                </svg>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div class="flex flex-col md:flex-row">
    <!-- \---Sidebar -->
    <div
      class="bg-gray-900 shadow-lg h-16 fixed bottom-0 mt-12 md:relative md:h-screen z-10 w-full md:w-48"
    >
      <div
        class="md:mt-12 md:w-48 md:fixed md:left-0 md:top-0 content-center md:content-start text-left justify-between"
      >
        <ul
          class="list-reset flex flex-row md:flex-col py-0 md:py-3 px-1 md:px-2 text-center md:text-left"
        >
          <li class="mr-3 flex-1">
            <a
              routerLinkActive="border-blue-600"
              routerLink="/dashboard/home"
              class="block py-1 md:py-3 pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-gray-800 hover:border-blue-500"
            >
              <i
                routerLinkActive="text-blue-600"
                class="fas fa-file pr-0 md:pr-3"
              ></i
              ><span
                class="pb-1 md:pb-0 text-xs md:text-base text-white md:text-white block md:inline-block"
                >Dashboard</span
              >
            </a>
          </li>
          <!-- <li class="mr-3 flex-1">
            <a
              routerLinkActive="border-blue-600"
              routerLink="/dashboard/categorykeys"
              class="block py-1 md:py-3 pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-gray-800 hover:border-blue-500"
            >
              <i
                routerLinkActive="text-blue-600"
                class="fa fa-box pr-0 md:pr-3"
              ></i
              ><span
                class="pb-1 md:pb-0 text-xs md:text-base text-gray-600 md:text-gray-400 block md:inline-block"
                >Category keys</span
              >
            </a>
          </li> -->
          <li class="mr-3 flex-1">
            <a
              routerLinkActive="border-blue-600"
              routerLink="/dashboard/keys"
              class="block py-1 md:py-3 pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-gray-800 hover:border-blue-500"
            >
              <i
                routerLinkActive="text-blue-600"
                class="fas fa-key pr-0 md:pr-3"
              ></i
              ><span
                class="pb-1 md:pb-0 text-xs md:text-base text-white md:text-white block md:inline-block"
                >Keys</span
              >
            </a>
          </li>
          <li class="mr-3 flex-1">
            <a
              routerLinkActive="border-blue-600"
              routerLink="/dashboard/documents"
              class="block py-1 md:py-3 pl-0 md:pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-gray-800 hover:border-blue-500"
            >
              <i
                routerLinkActive="text-blue-600"
                class="fas fa-file pr-0 md:pr-3"
              ></i
              ><span
                class="pb-1 md:pb-0 text-xs md:text-base text-white md:text-white block md:inline-block"
                >Documents</span
              >
            </a>
          </li>
          <li class="mr-3 flex-1">
            <a
              routerLinkActive="border-blue-600"
              routerLink="/dashboard/table-column-annotation"
              class="block py-1 md:py-3 pl-0 md:pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-gray-800 hover:border-blue-500"
            >
              <i
                routerLinkActive="text-blue-600"
                class="fas fa-table pr-0 md:pr-3"
              ></i
              ><span
                class="pb-1 ml-6 md:pb-0 text-xs md:text-base text-white md:text-white block md:inline-block"
                >Table Column Annotations</span
              >
            </a>
          </li>
          <li class="mr-3 flex-1" *ngIf="user.role === 0 || user.role === 'ADMIN'">
            <a
              routerLinkActive="border-blue-600"
              routerLink="/dashboard/users"
              class="block py-1 md:py-3 pl-0 md:pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-gray-800 hover:border-blue-500"
            >
              <i
                routerLinkActive="text-blue-600"
                class="fas fa-users pr-0 md:pr-3"
              ></i
              ><span
                class="pb-1 md:pb-0 text-xs md:text-base text-white md:text-white block md:inline-block"
                >Users</span
              >
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- \ Main page content -->
    <div class="main-content flex-1 bg-gray-100 mt-12 md:mt-2 pb-24 md:pb-5">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
