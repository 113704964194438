<div class="bg-blue-800 p-2 shadow text-xl text-white">
  <h3 class="font-bold pl-2">Dashboard</h3>
</div>

<div class="flex flex-wrap">
  <div class="w-full md:w-1/2 xl:w-1/3 p-3">
    <!--Metric Card-->
    <div
      class="bg-blue-100 border-b-4 border-blue-500 rounded-lg shadow-lg p-5"
    >
      <div class="flex flex-row items-center">
        <div class="flex-shrink pr-4">
          <div class="rounded-full p-5 bg-blue-600">
            <i class="fas fa-file fa-2x fa-inverse"></i>
          </div>
        </div>
        <div class="flex-1 text-right md:text-center">
          <h5 class="font-bold uppercase text-gray-600">Documents</h5>
          <h3 class="font-bold text-3xl">{{ (statistics$ | async).totalDocuments }}</h3>
        </div>
      </div>
    </div>
    <!--/Metric Card-->
  </div>
  <div class="w-full md:w-1/2 xl:w-1/3 p-3">
    <!--Metric Card-->
    <div
      class="bg-indigo-100 border-b-4 border-indigo-500 rounded-lg shadow-lg p-5"
    >
      <div class="flex flex-row items-center">
        <div class="flex-shrink pr-4">
          <div class="rounded-full p-5 bg-indigo-600">
            <i class="fas fa-key fa-2x fa-inverse"></i>
          </div>
        </div>
        <div class="flex-1 text-right md:text-center">
          <h5 class="font-bold uppercase text-gray-600">Keys</h5>
          <h3 class="font-bold text-3xl">{{ (statistics$ | async).totalKeys }}</h3>
        </div>
      </div>
    </div>
    <!--/Metric Card-->
  </div>
  <div class="w-full md:w-1/2 xl:w-1/3 p-3">
    <!--Metric Card-->
    <div class="bg-red-100 border-b-4 border-red-500 rounded-lg shadow-lg p-5">
      <div class="flex flex-row items-center">
        <div class="flex-shrink pr-4">
          <div class="rounded-full p-5 bg-red-600">
            <i class="fas fa-users fa-2x fa-inverse"></i>
          </div>
        </div>
        <div class="flex-1 text-right md:text-center">
          <h5 class="font-bold uppercase text-gray-600">Users</h5>
          <h3 class="font-bold text-3xl">
            {{ (statistics$ | async).totalUsers }}
            <!-- <span class="text-red-500"><i class="fas fa-caret-up"></i></span> -->
          </h3>
        </div>
      </div>
    </div>
    <!--/Metric Card-->
  </div>
</div>

<div class="flex flex-row flex-wrap flex-grow mt-2">
  <div class="w-full md:w-1/2 xl:w-1/3 p-3">
    <!--Graph Card-->
    <div class="bg-white border-transparent rounded-lg shadow-lg">
      <div
        class="bg-gray-400 uppercase text-gray-800 border-b-2 border-gray-500 rounded-tl-lg rounded-tr-lg p-2"
      >
        <h5 class="font-bold uppercase text-gray-600">Graph</h5>
      </div>
      <div class="p-5"></div>
    </div>
    <!--/Graph Card-->
  </div>

  <div class="w-full md:w-1/2 xl:w-1/3 p-3">
    <!--Graph Card-->
    <div class="bg-white border-transparent rounded-lg shadow-lg">
      <div
        class="bg-gray-400 border-b-2 border-gray-500 rounded-tl-lg rounded-tr-lg p-2"
      >
        <h5 class="font-bold uppercase text-gray-600">Graph</h5>
      </div>
      <div class="p-5"></div>
    </div>
    <!--/Graph Card-->
  </div>

  <div class="w-full md:w-1/2 xl:w-1/3 p-3">
    <!--Graph Card-->
    <div class="bg-white border-transparent rounded-lg shadow-lg">
      <div
        class="bg-gray-400 border-b-2 border-gray-500 rounded-tl-lg rounded-tr-lg p-2"
      >
        <h5 class="font-bold uppercase text-gray-600">Graph</h5>
      </div>
      <div class="p-5"></div>
    </div>
    <!--/Graph Card-->
  </div>
</div>
