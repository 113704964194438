import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-loader',
  templateUrl: './custom-loader.component.html',
  styleUrls: ['./custom-loader.component.sass']
})
export class CustomLoaderComponent implements OnInit {

  @Input() parameters: {
    title: '',
    imgUrl: '',
  };

  constructor() { }

  ngOnInit(): void {
  }

}
