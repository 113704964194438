import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  getItem(key: string) {
    return window.localStorage.getItem(key);
  }

  storeItem(key: string, value: any) {
    window.localStorage.setItem(key, value);
  }

  deleteItem(key: string) {
    window.localStorage.removeItem(key);
  }

  clearStorage() {
    window.localStorage.clear();
  }
}
