<div [ngStyle]="{'visibility': this.showModal ? 'visible' : 'hidden'}" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
  <div class="relative w-auto my-6 mx-auto max-w-3xl">
    <!--content-->
    <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
      <!--header-->
      <div class="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
        <h3 class="text-3xl font-semibold">
          {{ parameters.title }}
        </h3>
        <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-1 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" (click)="toggleModal()">
          <span class="bg-transparent text-black opacity-1 h-6 w-6 text-2xl block outline-none focus:outline-none">
            ×
          </span>
        </button>
      </div>
      <!--body-->
      <div class="relative p-6 flex-auto">
          <form [formGroup]="columnTableForm">
                <div formArrayName="columnTableAnnotations">
                    <table>
                        <tr *ngFor="let item of columnTableAnnotations.controls; let itemIndex=index" [formGroupName]="itemIndex">
                            <td>
                                <div class="mb-3 border-black-300 pt-0">
                                    <select formControlName="key" class="px-3 py-3 border-b border-black-200 rounded placeholder-gray-400 text-gray-700 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full">
                                          <option *ngFor="let key of parameters.keys" [selected]="this.selected" [value]="key.label">{{ key.label }}</option>
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div class="mb-3 ml-3 pt-0">
                                    <input formControlName="value" type="text" placeholder="The associated value here..." class="border-b border-black-200 rounded px-3 py-3 border-black-300 placeholder-gray-400 text-gray-700 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"/>
                                </div>
                            </td>
                            <td>
                                <div class="mb-2 ml-3 pt-0">
                                    <button (click)="addColumnTableAnnotation()" class="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1">
                                        +
                                    </button>
                                    <button (click)="deleteColumnTableAnnotation(itemIndex)" class="bg-red-500 text-white active:bg-green-600 font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1">
                                        -
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
          </form>
      </div>
      <!--footer-->
      <div class="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
        <button class="text-red-500 border-2 border-gray-200 rounded background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" (click)="toggleModal()">
          Close
        </button>
        <button (click)="saveAnnotatedTableColumns()" class="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" (click)="toggleModal()">
          Save Changes
        </button>
      </div>
    </div>
  </div>
</div>
<div [ngStyle]="{'visibility': this.showModal ? 'visible' : 'hidden'}" class="opacity-25 fixed inset-0 z-40 bg-black"></div>