<div class="container mx-auto h-full flex justify-center items-center mt-12">
  <div class="max-w-sm mx-auto flex p-6 bg-white rounded-lg shadow-xl border border-blue-500 border-grey-light">
    <div class="flex-shrink">
      <img class="h-12 w-12" src="assets/images/auth.png" alt="Authentication Logo">
    </div>
    <div class="ml-6 pt-1">
      <h4 class="text-xl text-gray-900 leading-tight">SmartRFC Studio</h4>
      <p class="text-base text-gray-600 leading-normal">Please authenticate yourself</p>
    </div>
  </div>
</div>

<div class="container mx-auto h-full flex justify-center items-center mt-12">
  <div class="w-1/3">
    <div class="border-blue-500 p-8 border-t-12 bg-white mb-6 rounded-lg shadow-lg border border-grey-light">
      <div class="mb-4">
        <label class="font-bold text-grey-darker block mb-2">Username</label>
        <input [(ngModel)]="this.payload.username" name="username" type="text"
          class="block appearance-none w-full bg-white border border-grey-light hover:border-grey px-2 py-2 rounded shadow"
          placeholder="Your Username" />
      </div>

      <div class="mb-4">
        <label class="font-bold text-grey-darker block mb-2">Password</label>
        <input [(ngModel)]="this.payload.password" name="username" type="password"
          class="block appearance-none w-full bg-white border border-grey-light hover:border-grey px-2 py-2 rounded shadow"
          placeholder="Your Password" />
      </div>

      <div class="flex items-center justify-between">
        <button [disabled]="!this.payload.username || !this.payload.password" (click)="this.login($event)"
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          Login
        </button>

        <a class="no-underline inline-block align-baseline font-bold text-sm text-blue hover:text-blue-dark float-right"
          href="#">
          Forgot Password ?
        </a>
      </div>
    </div>
  </div>
</div>
