import { Component, OnInit } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ITableColumnAnnotation, TableColumnAnnotationService } from '../../providers/table-column-annotation.service';
import { Validators, FormGroup } from '@angular/forms';
import { ToastService } from '../../providers/toast.service';
import { IKey, KeyService } from '../../providers/key.service';


@Component({
  selector: 'app-table-column-annotation',
  templateUrl: './table-column-annotation.component.html',
  styleUrls: ['./table-column-annotation.component.sass']
})
export class TableColumnAnnotationComponent implements OnInit {
  formClass = 'bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4';
  inputWrapperClass = 'grid grid-cols-2 gap-4';
  actionsWrapperClass = 'flex items-center justify-between';
  inputs =  [
    {
      groupClass: 'col-span-12 mb-4',
      labelClass: 'block text-gray-700 text-sm font-bold mb-2',
      inputClass:
        'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
      inputClassSuccess: 'border-green-500',
      inputClassError: 'border-red-500',
      helperClass: '',
      type: '',
      form: 'select',
      id: 'key',
      value: '',
      label: 'Key',
      placeholder: '',
      helperText: '',
      validators: [Validators.required],
      selectOptionValues$: new BehaviorSubject([
        { label: 'Select a key', value: ''},
      ])
    },
    {
      groupClass: 'col-span-12 mb-4',
      labelClass: 'block text-gray-700 text-sm font-bold mb-2',
      inputClass:
        'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
      inputClassSuccess: 'border-green-500',
      inputClassError: 'border-red-500',
      helperClass: '',
      type: 'text',
      form: 'input',
      id: 'value',
      value: '',
      label: 'Value',
      placeholder: 'Annotation value',
      helperText: '',
      validators: [Validators.required],
    },
  ];
  formActions = [];
  submitLoading = false;
  resetForm$ = new Subject<ITableColumnAnnotation>();
  testInformationKeys;

  
  tableColumnAnnotations$: Observable<Array<ITableColumnAnnotation>>;
  selectedTableColumnAnnotation: ITableColumnAnnotation;

  modalParameters = {
    title: '',
    message: '',
    operation: '',
    isOpen: false
  };

  formValue;
  constructor(private tableColumnAnnotationService: TableColumnAnnotationService,
              private keyService: KeyService,
              private toastService: ToastService) { }

  async ngOnInit() {
    this.tableColumnAnnotationService.loadTableColumnAnnotations();
    this.testInformationKeys = await this.keyService.getKeysOfCategory('test_information').toPromise();
    this.setForm();
    this.tableColumnAnnotations$ = this.tableColumnAnnotationService.getState().pipe(map((state) => state.data));
  }
  
  setForm() {
    const options = this.testInformationKeys.map(key => {
      return { label: key.label, value: key._id }
    });
    options.push({ label: 'Select a key', value: ''});
    this.formActions = [
      {
        type: 'button',
        id: 'submit',
        class:
          'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline',
        errorClass: 'bg-gray-500 text-white font-bold py-2 px-4 rounded',
        text: 'Save',
        validationRequired: true,
      },
    ];
    this.inputs = [
      {
        groupClass: 'col-span-12 mb-4',
        labelClass: 'block text-gray-700 text-sm font-bold mb-2',
        inputClass:
          'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
        inputClassSuccess: 'border-green-500',
        inputClassError: 'border-red-500',
        helperClass: '',
        type: '',
        form: 'select',
        id: 'key',
        value: '',
        label: 'Key',
        placeholder: '',
        helperText: '',
        validators: [Validators.required],
        selectOptionValues$: new BehaviorSubject(options.reverse())
      },
      {
        groupClass: 'col-span-12 mb-4',
        labelClass: 'block text-gray-700 text-sm font-bold mb-2',
        inputClass:
          'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
        inputClassSuccess: 'border-green-500',
        inputClassError: 'border-red-500',
        helperClass: '',
        type: 'text',
        form: 'input',
        id: 'value',
        value: '',
        label: 'Value',
        placeholder: 'Annotation value',
        helperText: '',
        validators: [Validators.required],
      },
    ];
  }

  onActionPerformed($event: { id: string; form: FormGroup }): void {
    switch ($event.id) {
      case 'submit':
        if ($event.form.valid) {
          this.addTableColumnAnnotation($event.form.value);
        }
        break;
      case 'cancel':
        this.resetForm$.next();
        this.setForm();
        break;
      case 'update':
        if ($event.form.valid) {
          this.modalParameters.title = 'Update Column Annotation';
          this.modalParameters.message = 'Are you sure you want to update this annotation ?';
          this.modalParameters.operation = 'update';
          this.modalParameters.isOpen = true;
          this.formValue = $event.form.value;
        }
        break;
    }
  }

  addTableColumnAnnotation(payload: ITableColumnAnnotation): void {
    const tableColumnAnnotationPayload: ITableColumnAnnotation = {
      value: payload.value,
      key: payload.key,
      createdAt: new Date()
    };
    const keyObject = this.testInformationKeys.filter(key => key._id === payload.key)[0];
    this.submitLoading = true;
    this.tableColumnAnnotationService.addTableColumnAnnotation(tableColumnAnnotationPayload).subscribe((res) => {
      this.submitLoading = false;
      tableColumnAnnotationPayload._id = res._id;
      tableColumnAnnotationPayload.key = keyObject;
      this.tableColumnAnnotationService.saveTableColumnAnnotation(tableColumnAnnotationPayload);
      this.resetForm$.next();
      this.toastService.notify('success', 'Column Annotation successfully added.');
    },
    error => {
      this.submitLoading = false;
      this.toastService.notify('error', 'It seems like that value is already used to annotate another column.');
    }
    );
  }

  updateTableColumnAnnotation(payload): void {
    this.submitLoading = true;
    payload.updatedAt = new Date();
    const keyObject = this.testInformationKeys.filter(key => key._id === payload.key)[0];
    this.tableColumnAnnotationService
      .updateTableColumnAnnotation(payload, this.selectedTableColumnAnnotation._id)
      .subscribe((res) => {
        this.formValue._id = res._id;
        this.submitLoading = false;
        this.formValue._id = res._id;
        this.formValue.key = keyObject;
        this.formValue.createdAt = res.createdAt;
        this.tableColumnAnnotationService.updateTableColumnAnnotationFromState(this.formValue);
        this.resetForm$.next();
        this.setForm();
        this.toastService.notify('warning', 'Column Annotation successfully updated !');
      },
      error => {
        this.submitLoading = false;
        this.toastService.notify('error', 'It seems like that value is already used to annotate another column.');
      }
      );
  }

  async updateTableColumnAnnotationForm(tableColumnAnnotation) {
    this.selectedTableColumnAnnotation = JSON.parse(JSON.stringify(tableColumnAnnotation));
    this.selectedTableColumnAnnotation.key = tableColumnAnnotation.key._id;
    this.resetForm$.next({
      value: tableColumnAnnotation.value,
      key: tableColumnAnnotation.key._id || JSON.parse(JSON.stringify((await this.tableColumnAnnotationService.getAll().toPromise()).find(annotation => annotation._id === tableColumnAnnotation._id).key))._id,
    });
    this.formActions = [
      {
        type: 'button',
        id: 'update',
        class:
          'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline',
        errorClass: 'bg-gray-500 text-white font-bold py-2 px-4 rounded',
        text: 'Update',
        validationRequired: true,
      },
      {
        type: 'button',
        id: 'cancel',
        class:
          'bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline',
        errorClass: 'bg-gray-500 text-white font-bold py-2 px-4 rounded',
        text: 'Cancel',
        validationRequired: false,
      },
    ];
    document.getElementById('form-title').scrollIntoView({behavior: 'smooth'});
  }

  deleteTableColumnAnnotation(tableColumnAnnotation: ITableColumnAnnotation): void {
    this.modalParameters.title = 'Delete Table Column Annotation';
    this.modalParameters.message = 'Are you sure you want to delete this annotation ?';
    this.modalParameters.operation = 'delete';
    this.modalParameters.isOpen = true;
    this.formValue = tableColumnAnnotation;
  }

  manageOperation(emittedValue): void {
    if (emittedValue.proceed) {
      switch (emittedValue.operation) {
        case 'update':
          this.updateTableColumnAnnotation(this.formValue);
          break;
        case 'delete':
          this.tableColumnAnnotationService.deleteTableColumnAnnotation(this.formValue._id).subscribe((res) => {
            this.tableColumnAnnotationService.deleteTableColumnAnnotationFromState(this.formValue);
            this.resetForm$.next();
            this.setForm();
            this.toastService.notify('warning', 'Column Annotation successfully deleted !');
          });
          break;
      }
    }
    this.modalParameters.isOpen = false;
  }

}
