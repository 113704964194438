import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const API_URL = environment.apiUrl;

export class HttpClientBase {
  constructor(private http: HttpClient) {}

  protected post<T>(
    payload: any,
    endpoint: string,
    baseUrl?: string,
  ): Observable<T> {
    const url = baseUrl ? `${baseUrl}${endpoint}` : `${API_URL}${endpoint}`;
    return this.http.post<T>(url, payload);
  }

  protected get<T>(endpoint: string, baseUrl?: string): Observable<T> {
    const url = baseUrl ? `${baseUrl}${endpoint}` : `${API_URL}${endpoint}`;
    return this.http.get<T>(url);
  }

  protected put<T>(
    payload: any,
    endpoint: string,
    baseUrl?: string,
  ): Observable<T> {
    const url = baseUrl ? `${baseUrl}${endpoint}` : `${API_URL}${endpoint}`;
    return this.http.put<T>(url, payload);
  }

  protected delete<T>(endpoint: string, baseUrl?: string): Observable<T> {
    const url = baseUrl ? `${baseUrl}${endpoint}` : `${API_URL}${endpoint}`;
    return this.http.delete<T>(url);
  }
}
