import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from 'src/app/core/store/store';
import { Observable } from 'rxjs';

const PAGE_SIZE = 8;
const DEFAULT_STATE: UserState = {
  loaded: false,
  loading: false,
  error: false,
  currentPage: 0,
  data: [],
};

@Injectable({
  providedIn: 'root'
})
export class UserService extends Store<UserState> {
  currentState: UserState;
  constructor(http: HttpClient) {
    super(http, DEFAULT_STATE);
    this.getState().subscribe((state) => (this.currentState = state));
  }

  saveUser(user: IUser): void {
    this.setState({
      ...this.currentState,
      data: [...this.currentState.data, user],
    });
  }

  updateUserFromState(user: IUser): void {
    const previousState = [...this.currentState.data].filter(vuser => vuser._id !== user._id);
    this.setState({
      ...this.currentState,
      data: [...previousState, user]
    });
  }

  deleteUserFromState(user: IUser): void {
    const previousState = [...this.currentState.data].filter(vuser => vuser._id !== user._id);
    this.setState({
      ...this.currentState,
      data: [...previousState]
    });
  }

  loadUsers(): void {
    if (this.currentState.loaded || this.currentState.loading) {
      return;
    }
    const currentPage = this.currentState.currentPage + 1;
    this.setState({
      ...this.currentState,
      loading: true,
      currentPage,
    });
    this.get<Array<IUser>>(`/users`).subscribe(
      (users) => {
        this.setState({
          ...this.currentState,
          data: [...this.currentState.data, ...users],
          loaded: true,
          loading: false,
        });
      },
      (err) => {
        this.setState({
          ...this.currentState,
          error: true,
          loading: false,
          loaded: false,
        });
      },
    );
  }

  addUser(payload: IUser): Observable<IUser> {
    return this.post<IUser>(payload, '/users/auth/register');
  }

  updateUser(payload: IUser, id: string): Observable<IUser> {
    return this.put<IUser>(payload, `/users/${id}`);
  }

  deleteUser(id: string): Observable<IUser> {
    return this.delete<IUser>(`/users/${id}`);
  }

}

export enum Role {
  ADMIN, USER
}

export interface IUser {
  firstname: string;
  lastname: string;
  username: string;
  password: string;
  passwordConfirm?: string;
  email: string;
  role: Role;
  createdAt?: any;
  updatedAt?: any;
  _id?: any;
  id?: any;
}

export interface UserState {
  loaded: boolean;
  loading: boolean;
  error: boolean;
  currentPage: number;
  data: Array<IUser>;
}
