import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-regular-modal',
  templateUrl: './regular-modal.component.html',
  styleUrls: ['./regular-modal.component.sass']
})
export class RegularModalComponent implements OnInit {

  @Input() showModal;
  @Output() showModalStateChange: EventEmitter<object> = new EventEmitter<object>();
  @Output() onSaveAnnotatedTableColumns: EventEmitter<object> = new EventEmitter<object>();
  @Input() parameters;
  columnTableForm: FormGroup;
  selected;
  
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.selected = this.parameters.columnsTable?.length > 0 ? true : false;
    this.columnTableForm = this.formBuilder.group({
      columnTableAnnotations: this.formBuilder.array([this.formBuilder.group({
        key: '',
        value: ''
      })], Validators.required)
    });
    this.populateForm();
  }

  populateForm() {
    if (this.parameters.columnsTable?.length > 0) {
      this.columnTableAnnotations.clear();
      this.parameters.columnsTable.map(columnAnnotation => this.columnTableAnnotations.push(this.formBuilder.group({
        key: columnAnnotation.key,
        value: columnAnnotation.value
      })));
    }
  }

  get columnTableAnnotations() {
    return this.columnTableForm.get('columnTableAnnotations') as FormArray;
  }

  addColumnTableAnnotation() {
    this.columnTableAnnotations.push(this.formBuilder.group({key: '', value: ''}));
  }

  deleteColumnTableAnnotation(index) {
    if (index === 0) {
      return;
    }
    this.columnTableAnnotations.removeAt(index);
  }

  toggleModal() {
    this.showModal = !this.showModal;
    this.showModalStateChange.emit({modalState: this.showModal});
  }

  saveAnnotatedTableColumns() {
    const tableColumnAnnotations = this.columnTableAnnotations.value.filter(annotation => annotation.key !== "" && annotation.value !== "");
    this.onSaveAnnotatedTableColumns.emit({annotatedColumns: tableColumnAnnotations});
  }

}
