import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IUser, Role, UserService } from '../../providers/user.service';
import { ToastService } from '../../providers/toast.service';
import { map } from 'rxjs/operators';
import { FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.sass']
})
export class UserComponent implements OnInit {
  formClass = 'bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4';
  inputWrapperClass = 'grid grid-cols-2 gap-4';
  actionsWrapperClass = 'flex items-center justify-between';
  inputs = [];
  formActions = [];
  submitLoading = false;
  resetForm$ = new Subject<IUser>();

  users$: Observable<Array<IUser>>;
  selectedUser: IUser;

  modalParameters = {
    title: '',
    message: '',
    operation: '',
    isOpen: false
  };

  formValue;
  updateForm = false
  constructor(private userService: UserService, private toastService: ToastService) { }

  ngOnInit(): void {
    this.setForm();
    this.userService.loadUsers();
    this.users$ = this.userService.getState().pipe(map((state) => state.data));
  }

  setForm(): void {
    this.formActions = [
      {
        type: 'button',
        id: 'submit',
        class:
          'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline',
        errorClass: 'bg-gray-500 text-white font-bold py-2 px-4 rounded',
        text: 'Save',
        validationRequired: true,
      },
    ];
    this.inputs = [
      {
        groupClass: 'col-span-12 mb-4',
        labelClass: 'block text-gray-700 text-sm font-bold mb-2',
        inputClass:
          'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
        inputClassSuccess: 'border-green-500',
        inputClassError: 'border-red-500',
        helperClass: '',
        type: 'text',
        form: 'input',
        id: 'firstname',
        value: '',
        label: 'Firstname',
        placeholder: 'Firstname',
        helperText: '',
        validators: [Validators.required],
      },
      {
        groupClass: 'col-span-12 mb-4',
        labelClass: 'block text-gray-700 text-sm font-bold mb-2',
        inputClass:
          'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
        inputClassSuccess: 'border-green-500',
        inputClassError: 'border-red-500',
        helperClass: '',
        type: 'text',
        form: 'input',
        id: 'lastname',
        value: '',
        label: 'Lastname',
        placeholder: 'Lastname',
        helperText: '',
        validators: [Validators.required],
      },
      {
        groupClass: 'col-span-12 mb-4',
        labelClass: 'block text-gray-700 text-sm font-bold mb-2',
        inputClass:
          'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
        inputClassSuccess: 'border-green-500',
        inputClassError: 'border-red-500',
        helperClass: '',
        type: 'text',
        form: 'input',
        id: 'username',
        value: '',
        label: 'Username',
        placeholder: 'Username',
        helperText: '',
        validators: [Validators.required],
      },
      {
        groupClass: 'col-span-12 mb-4',
        labelClass: 'block text-gray-700 text-sm font-bold mb-2',
        inputClass:
          'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
        inputClassSuccess: 'border-green-500',
        inputClassError: 'border-red-500',
        helperClass: '',
        type: 'email',
        form: 'input',
        id: 'email',
        value: '',
        label: 'Email Address',
        placeholder: 'Email Address',
        helperText: '',
        validators: [Validators.required, Validators.email],
      },
      {
        groupClass: 'col-span-12 mb-4',
        labelClass: 'block text-gray-700 text-sm font-bold mb-2',
        inputClass:
          'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
        inputClassSuccess: 'border-green-500',
        inputClassError: 'border-red-500',
        helperClass: '',
        type: '',
        form: 'select',
        id: 'role',
        value: '',
        label: 'Role',
        placeholder: '',
        helperText: '',
        validators: [Validators.required],
        selectOptionValues$: new BehaviorSubject([
          { label: 'Select a role', value: ''},
          { label: 'ADMIN', value: Role.ADMIN },
          { label: 'USER', value: Role.USER },
        ])
      },
      {
        groupClass: 'col-span-12 mb-4',
        labelClass: 'block text-gray-700 text-sm font-bold mb-2',
        inputClass:
          'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
        inputClassSuccess: 'border-green-500',
        inputClassError: 'border-red-500',
        helperClass: '',
        type: 'password',
        form: 'input',
        id: 'password',
        value: '',
        label: 'Password',
        placeholder: 'Password',
        helperText: '',
        validators: this.updateForm ? [Validators.required] : [],
      },
      {
        groupClass: 'col-span-12 mb-4',
        labelClass: 'block text-gray-700 text-sm font-bold mb-2',
        inputClass:
          'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
        inputClassSuccess: 'border-green-500',
        inputClassError: 'border-red-500',
        helperClass: '',
        type: 'password',
        form: 'input',
        id: 'passwordConfirm',
        value: '',
        label: 'Confirm Password',
        placeholder: 'Confirm Password',
        helperText: '',
        validators: this.updateForm ? [Validators.required] : [],
      },
    ];
  }

  onActionPerformed($event: { id: string; form: FormGroup }): void {
    switch ($event.id) {
      case 'submit':
        if ($event.form.valid) {
          this.addUser($event.form.value);
        }
        break;
      case 'cancel':
        this.resetForm$.next();
        this.setForm();
        break;
      case 'update':
        if ($event.form.valid) {
          this.modalParameters.title = 'Update User';
          this.modalParameters.message = 'Are you sure you want to update this user ?';
          this.modalParameters.operation = 'update';
          this.modalParameters.isOpen = true;
          this.formValue = $event.form.value;
        }
        break;
    }
  }

  addUser(payload: IUser): void {
    const userPayload: IUser = {
      firstname: payload.firstname,
      lastname: payload.lastname,
      username: payload.username,
      email: payload.email,
      role: payload.role.toString() === '0' ? Role.ADMIN : Role.USER,
      password: payload.password,
      passwordConfirm: payload.passwordConfirm,
      createdAt: new Date()
    };
    this.submitLoading = true;
    this.userService.addUser(userPayload).subscribe(res => {
      this.submitLoading = false;
      userPayload._id = res._id;
      this.userService.saveUser(userPayload);
      this.resetForm$.next();
      this.toastService.notify('success', 'User successfully added.');
    },
      error => {
        this.toastService.notify('error', error.error.message, 6000);
        this.submitLoading = false;
      });
  }

  updateUser(payload: IUser): void {
    this.submitLoading = true;
    payload.updatedAt = new Date();
    payload.createdAt = this.selectedUser.createdAt;
    payload.role = payload.role.toString() === '0' ? Role.ADMIN : Role.USER;
    this.userService
      .updateUser(payload, this.selectedUser._id)
      .subscribe((res) => {
        this.formValue._id = res._id;
        this.submitLoading = false;
        this.formValue._id = res._id;
        this.userService.updateUserFromState(this.formValue);
        this.resetForm$.next();
        this.setForm();
        this.toastService.notify('warning', 'User successfully updated !');
      },
        (error) =>  {
          this.toastService.notify('error', error.error.message, 6000);
          this.submitLoading = false;
        });
        this.updateForm = false;
  }

  updateUserForm(user: IUser): void {
    this.selectedUser = user;
    this.updateForm = true;
    this.resetForm$.next({
      firstname: user.firstname,
      lastname: user.lastname,
      username: user.username,
      email: user.email,
      role: user.role,
      password: '',
      passwordConfirm: ''
    });
    this.formActions = [
      {
        type: 'button',
        id: 'update',
        class:
          'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline',
        errorClass: 'bg-gray-500 text-white font-bold py-2 px-4 rounded',
        text: 'Update',
        validationRequired: true,
      },
      {
        type: 'button',
        id: 'cancel',
        class:
          'bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline',
        errorClass: 'bg-gray-500 text-white font-bold py-2 px-4 rounded',
        text: 'Cancel',
        validationRequired: false,
      },
    ];
    document.getElementById('form-title').scrollIntoView({behavior: 'smooth'});
  }

  deleteUser(user: IUser): void {
    this.modalParameters.title = 'Delete User';
    this.modalParameters.message = 'Are you sure you want to delete this user ?';
    this.modalParameters.operation = 'delete';
    this.modalParameters.isOpen = true;
    this.formValue = user;
  }

  manageOperation(emittedValue): void {
    if (emittedValue.proceed) {
      switch (emittedValue.operation) {
        case 'update':
          this.updateUser(this.formValue);
          break;
        case 'delete':
          this.userService.deleteUser(this.formValue._id).subscribe((res) => {
            this.userService.deleteUserFromState(this.formValue);
            this.toastService.notify('warning', 'User successfully deleted !');
          });
          break;
      }
    }
    this.modalParameters.isOpen = false;
    this.updateForm = false;
  }

}
