import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @Input() parameters: {
    title: '',
    message: '',
    operation: '',
    isOpen: boolean
  };

  @Output() operationEvent: EventEmitter<object> = new EventEmitter<object>();

  constructor() { }

  ngOnInit(): void {
  }

  buttonClicked(proceed: boolean): void {
    this.operationEvent.emit({proceed,  operation: this.parameters.operation});
    this.parameters.isOpen = false;
  }

}
