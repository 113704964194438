<app-modal (operationEvent)="manageOperation($event)" [parameters]="modalParameters"></app-modal>

<div class="bg-blue-800 p-2 shadow text-xl text-white">
  <h3 class="font-bold pl-2" id="form-title">Table Column Annotations</h3>
</div>

<div class="flex flex-row flex-wrap flex-grow mt-2">
  <div class="w-full md:w-2/3 xl:w-2/3 p-3 m-auto">
    <div class="bg-white border-transparent rounded-lg shadow-lg">
      <div
        class="bg-gray-400 uppercase text-gray-800 border-b-2 border-gray-500 rounded-tl-lg rounded-tr-lg p-2"
      >
        <h5 class="font-bold uppercase text-gray-600" id="table-column-annotation-form">Table Column Annotation Form</h5>
      </div>
      <div class="p-5">
        <app-form
          [formClass]="this.formClass"
          [inputWrapperClass]="this.inputWrapperClass"
          [inputs]="this.inputs"
          [actionsWrapperClass]="this.actionsWrapperClass"
          [actions]="this.formActions"
          (actionPerformed$)="this.onActionPerformed($event)"
          [submitLoading]="this.submitLoading"
          [resetForm$]="this.resetForm$"
        ></app-form>
      </div>
    </div>
  </div>

  <div class="w-full p-8">
    <div class="bg-white border-transparent rounded-lg shadow-lg">
      <div
        class="bg-gray-400 uppercase text-gray-800 border-b-2 border-gray-500 rounded-tl-lg rounded-tr-lg p-2"
      >
        <h5 class="font-bold uppercase text-gray-600">Table Column Annotations list</h5>
      </div>
      <div class="p-5">
        <table class="table-auto w-full m-auto">
          <thead>
            <tr>
              <th class="px-4 py-2">Key</th>
              <th class="px-4 py-2">Value</th>
              <th class="px-4 py-2">Created At</th>
              <th class="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let tableColumnAnnotation of this.tableColumnAnnotations$ | async; let i = index"
              [class]="i % 2 === 0 ? '' : 'bg-gray-100'"
            >
            <td class="border px-4 py-2">"label": {{ tableColumnAnnotation.key?.label  }}, "description": {{ tableColumnAnnotation.key?.description  }}</td>
              <td class="border px-4 py-2">{{ tableColumnAnnotation.value }}</td>
              <td class="border px-4 py-2">
                {{ tableColumnAnnotation.createdAt | date: 'full' }}
              </td>
              <td class="border px-4 py-2">
                <button
                  (click)="this.updateTableColumnAnnotationForm(tableColumnAnnotation)"
                  class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 ml-1 mt-1 rounded text-xs"
                >
                  Update
                </button>
                <button
                  (click)="this.deleteTableColumnAnnotation(tableColumnAnnotation)"
                  class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 ml-1 mt-1 rounded text-xs"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
