import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../providers/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass'],
})
export class DashboardComponent implements OnInit {
  user;
  constructor(public authService: AuthService) {
    this.user = this.authService.getAuthenticatedUser();
  }


  ngOnInit(): void {
  }
}
