import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './core/pages/dashboard/dashboard.component';
import { KeyComponent } from './core/pages/key/key.component';
import { CategoryKeyComponent } from './core/pages/category-key/category-key.component';
import { DocumentComponent } from './core/pages/document/document.component';
import { AnnotationComponent } from './core/pages/annotation/annotation.component';
import { LoginComponent } from './core/pages/login/login.component';
import { HomeComponent } from './core/pages/home/home.component';
import { AuthGuardService } from './core/guards/auth-guard.service';
import {UserComponent} from "./core/pages/user/user.component";
import { TableColumnAnnotationComponent } from './core/pages/table-column-annotation/table-column-annotation.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'keys',
        component: KeyComponent,
      },
      // {
      //   path: 'categorykeys',
      //   component: CategoryKeyComponent,
      // },
      {
        path: 'documents',
        component: DocumentComponent,
      },
      {
        path: 'table-column-annotation',
        component: TableColumnAnnotationComponent,
      },
      {
        path: 'users',
        component: UserComponent,
      },
      {
        path: '**',
        redirectTo: '/dashboard/home',
      },
    ],
  },
  {
    path: 'annotation/:document',
    component: AnnotationComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: '**',
    redirectTo: '/dashboard/home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
