import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { RouterModule } from '@angular/router';

import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { FormComponent } from './components/form/form.component';
import { KeyComponent } from './pages/key/key.component';
import { CategoryKeyComponent } from './pages/category-key/category-key.component';
import { DocumentComponent } from './pages/document/document.component';
import { AnnotationComponent } from './pages/annotation/annotation.component';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { ModalComponent } from './components/modal/modal.component';
import { UserComponent } from './pages/user/user.component';
import { RegularModalComponent } from './components/regular-modal/regular-modal.component';
import { TableColumnAnnotationComponent } from './pages/table-column-annotation/table-column-annotation.component';
import { CustomLoaderComponent } from './components/custom-loader/custom-loader.component';
import { EscapeHtmlPipe } from './utils/pipes/keep-html.pipe';

@NgModule({
  declarations: [
    DashboardComponent,
    FormComponent,
    KeyComponent,
    CategoryKeyComponent,
    DocumentComponent,
    AnnotationComponent,
    LoginComponent,
    HomeComponent,
    ModalComponent,
    UserComponent,
    RegularModalComponent,
    TableColumnAnnotationComponent,
    CustomLoaderComponent,
    EscapeHtmlPipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    PdfViewerModule,
  ],
})
export class CoreModule {}
