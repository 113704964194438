import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DashboardService } from '../../providers/dashboard.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {

  statistics$: Observable<object>;

  constructor(private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.dashboardService.loadData();
    this.statistics$ = this.dashboardService.getState().pipe(map((state) => state.data));
  }

}
