import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastService } from 'src/app/core/providers/toast.service';

import {
  DocumentService,
  IDocument,
} from 'src/app/core/providers/document.service';
import { AnnotationService } from '../../providers/annotation.service';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
})
export class DocumentComponent implements OnInit {
  formClass = 'bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4';
  inputWrapperClass = 'grid grid-cols-2 gap-4';
  actionsWrapperClass = 'flex items-center justify-between';
  inputs = [];
  formActions = [];
  submitLoading = false;
  resetForm$ = new Subject<IDocument>();

  documents$: Observable<Array<IDocument>>;
  selectedDocument: IDocument;

  modalParameters = {
    title: '',
    message: '',
    operation: '',
    isOpen: false,
  };

  customLoaderParameters = {
    title: 'Please wait while upload and process your document...',
    imgUrl: 'assets/images/loader-1.gif',
  };

  formValue;
  documentToUpload: IDocument;
  constructor(
    public documentSvc: DocumentService, 
    private toastService: ToastService, 
    private annotationSvc: AnnotationService,
    ) {}

  ngOnInit(): void {
    this.setForm();
    this.documentSvc.loadDocuments();
    this.documents$ = this.documentSvc
      .getState()
      .pipe(map((state) => state.data));
  }

  setForm(): void {
    this.formActions = [
      {
        type: 'button',
        id: 'submit',
        class:
          'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline',
        errorClass: 'bg-gray-500 text-white font-bold py-2 px-4 rounded',
        text: 'Save',
        validationRequired: true,
      },
    ];
    this.inputs = [
      {
        groupClass: 'col-span-12 mb-4',
        labelClass: 'block text-gray-700 text-sm font-bold mb-2',
        inputClass:
          'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
        inputClassSuccess: 'border-green-500',
        inputClassError: 'border-red-500',
        helperClass: '',
        type: 'text',
        form: 'input',
        id: 'idDocument',
        value: '',
        label: 'ID Document',
        placeholder: 'ID Document',
        helperText: '',
        validators: [Validators.required],
      },
      {
        groupClass: 'col-span-12 mb-4',
        labelClass: 'block text-gray-700 text-sm font-bold mb-2',
        inputClass:
          'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
        inputClassSuccess: 'border-green-500',
        inputClassError: 'border-red-500',
        helperClass: '',
        type: 'file',
        accept: 'application/pdf',
        form: 'input',
        id: 'file',
        value: '',
        label: 'File',
        placeholder: 'File',
        helperText: '',
        validators: [Validators.required],
      },
    ];
  }

  onActionPerformed($event: { id: string; form: FormGroup }): void {
    switch ($event.id) {
      case 'submit':
        if ($event.form.valid) {
          this.documentToUpload = $event.form.value;
          this.verifyDocument($event.form.value);
        }
        break;
      case 'cancel':
        this.resetForm$.next();
        this.setForm();
        break;
      case 'update':
        if ($event.form.valid) {
          this.updateDocument($event.form.value);
        }
        break;
    }
  }

  addDocument(payload: IDocument): void {
    this.submitLoading = true;
    this.documentSvc.addDocument(payload).subscribe((res) => {
      this.submitLoading = false;
      this.documentSvc.saveDocument(res);
      this.resetForm$.next();
      this.toastService.notify('success', 'Document successfully added.');
    });
  }

  verifyDocument(payload: IDocument): void {
    this.submitLoading = true;
    this.documentSvc
    .verifyDocumentAnnotation(payload)
    .subscribe(
      res => {
        if (res.annotated) {
          this.submitLoading = false;
          let href = `${window.location.origin}/annotation/${res.template.id}`;
          let link = `<a href="${href}" target="_blank" style="color: lightskyblue; text-decoration: underline;">HERE</a>`;
          this.modalParameters.title = 'Document Found';
          this.modalParameters.message = 
          `It seems like this document or lab report is already exist and may be annotated.
          You can check this by clicking <strong>${link}</strong>. <br/>
          Do you want to proceed anyway ?
          <br/><br/>
          <b><i>***Important Information***</i></b>
          <br/>
          By similar template we mean document that has same context in the way
          that we are performing annotation.
          <br/>
          For example :
          <br/>
          This two below lab has the same context and would be considered as the template.
          <img src="assets/images/alert.png" />`;
          this.modalParameters.operation = 'upload';
          this.modalParameters.isOpen = true;
        } else {
          this.addDocument(this.documentToUpload);
        }
      },
      error => {
        this.submitLoading = false;
        this.toastService.notify('error', 'Oups 😕... An error has occured when trying to verify the document, please try again !');
        console.log(error);
      }
    )
  }

  updateDocument(payload: IDocument): void {
    this.submitLoading = true;
    this.documentSvc
      .updateDocument(payload, this.selectedDocument._id)
      .subscribe((res) => {
        window.location.reload();
      });
  }

  updateDocumentForm(document: IDocument): void {
    this.selectedDocument = document;
    this.resetForm$.next({
      idDocument: document.idDocument,
      file: document.file,
    });
    this.formActions = [
      {
        type: 'button',
        id: 'update',
        class:
          'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline',
        errorClass: 'bg-gray-500 text-white font-bold py-2 px-4 rounded',
        text: 'Update',
        validationRequired: true,
      },
      {
        type: 'button',
        id: 'cancel',
        class:
          'bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline',
        errorClass: 'bg-gray-500 text-white font-bold py-2 px-4 rounded',
        text: 'Cancel',
        validationRequired: false,
      },
    ];
  }

  deleteDocument(document: IDocument): void {
    this.modalParameters.title = 'Delete Document';
    this.modalParameters.message = 'Are you sure you want to delete this document ?';
    this.modalParameters.operation = 'delete';
    this.modalParameters.isOpen = true;
    this.formValue = document;
  }

  manageOperation(emittedValue): void {
    if (emittedValue.proceed) {
      switch (emittedValue.operation) {
        case 'update':
          this.updateDocument(this.formValue);
          this.toastService.notify('warning', 'Document successfully updated !');
          break;
        case 'delete':
          this.documentSvc.deleteDocument(this.formValue._id).subscribe((res) => {
            this.documentSvc.deleteDocumentFromState(this.formValue);
            this.toastService.notify('warning', 'Document successfully deleted !');
            this.annotationSvc.reloadAnnotation().subscribe(
              res => console.log('Reload, done!'),
              error => console.log('An error has occured when reloading annotation!'),
            )
          });
          break;
          case 'upload':
            this.addDocument(this.documentToUpload);
            break;
      }
    }
    this.modalParameters.isOpen = false;
    this.resetForm$.next();
    this.setForm();
  }
}
