<form *ngIf="this.form" [class]="this.formClass" [formGroup]="this.form">
  <div [class]="this.inputWrapperClass">
    <div *ngFor="let input of this.inputs" [class]="input.groupClass">
      <label [class]="input.labelClass" [for]="input.id">{{
        input.label
      }}</label>
      <input
        *ngIf="input.form === 'input' && input.type !== 'file'"
        [type]="input.type"
        [class]="
          this.getInputForm(input.id)?.errors &&
          this.getInputForm(input.id)?.touched
            ? input.inputClass + ' ' + input.inputClassError
            : input.inputClass
        "
        [id]="input.id"
        [attr.aria-describedby]="'help' + input.id"
        [placeholder]="input.placeholder"
        [formControlName]="input.id"
        [name]="input.id"
      />
      <input
        *ngIf="input.form === 'input' && input.type === 'file'"
        (change)="onFileChange($event, input)"
        [type]="input.type"
        [class]="
          this.getInputForm(input.id)?.errors &&
          this.getInputForm(input.id)?.touched
            ? input.inputClass + ' ' + input.inputClassError
            : input.inputClass
        "
        [id]="input.id"
        [attr.aria-describedby]="'help' + input.id"
        [placeholder]="input.placeholder"
        [name]="input.id"
        [accept]="input.accept"
      />
      <textarea
        *ngIf="input.form === 'textArea'"
        [class]="
          this.getInputForm(input.id)?.errors &&
          this.getInputForm(input.id)?.touched
            ? input.inputClass + ' ' + input.inputClassError
            : input.inputClass
        "
        [id]="input.id"
        [attr.aria-describedby]="'help' + input.id"
        [placeholder]="input.placeholder"
        [formControlName]="input.id"
        [name]="input.id"
      ></textarea>
      <ng-container *ngIf="input.form === 'select'">
        <select
          [class]="
            this.getInputForm(input.id)?.errors &&
            this.getInputForm(input.id)?.touched
              ? input.inputClass + ' ' + input.inputClassError
              : input.inputClass
          "
          [id]="input.id"
          [attr.aria-describedby]="'help' + input.id"
          [formControlName]="input.id"
          [name]="input.id"
        >
          <option
            *ngFor="let option of input.selectOptionValues$ | async"
            [value]="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </ng-container>
      <small
        *ngIf="input.helperText"
        [id]="'help' + input.id"
        [class]="input.helperClass"
        >{{ input.helperText }}</small
      >
    </div>
  </div>
  <div [class]="this.actionsWrapperClass">
    <ng-container *ngFor="let action of this.actions">
      <button
        *ngIf="action.type === 'button'"
        [class]="
          action.validationRequired && this.form.invalid
            ? action.errorClass
            : action.class
        "
        type="button"
        (click)="actionsPerformed(action.id)"
        [disabled]="
          (action.validationRequired && this.form.invalid) || this.submitLoading
        "
      >
        {{ action.text }}
      </button>
      <a
        *ngIf="action.type === 'a'"
        class=""
        (click)="actionsPerformed(action.id)"
      >
        {{ action.text }}
      </a>
    </ng-container>
  </div>
</form>
