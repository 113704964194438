import { Injectable } from '@angular/core';

import { Store } from 'src/app/core/store/store';
import { HttpClient } from '@angular/common/http';
import { IDocument } from './document.service';

const DEFAULT_STATE: DashboardState = {
  loaded: false,
  loading: false,
  error: false,
  data: { },
};

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends Store<DashboardState> {
  currentState: DashboardState;

  constructor(http: HttpClient) {
    super(http, DEFAULT_STATE);
    this.getState().subscribe((state) => (this.currentState = state));
  }

  loadData(): void {
    if (this.currentState.loaded || this.currentState.loading) {
      return;
    }
    this.setState({
      ...this.currentState,
      loading: true,
    });
    this.getTotalDocuments();
    this.getTotalKeys();
    this.getTotalUsers();
  }

  getTotalDocuments(): void {
    this.get<Array<IDocument>>(`/documents`).subscribe(
      (documents) => {
        this.setState({
          ...this.currentState,
          data: { ...this.currentState.data, totalDocuments: documents.length },
          loaded: true,
          loading: false,
        });
      },
      (err) => {
        this.setState({
          ...this.currentState,
          error: true,
          loading: false,
          loaded: false,
        });
      },
    );
  }

  getTotalKeys(): void {
    this.get<Array<IDocument>>(`/keys`).subscribe(
      (keys) => {
        this.setState({
          ...this.currentState,
          data: { ...this.currentState.data, totalKeys: keys.length },
          loaded: true,
          loading: false,
        });
      },
      (err) => {
        this.setState({
          ...this.currentState,
          error: true,
          loading: false,
          loaded: false,
        });
      },
    );
  }

  getTotalUsers(): void {
    this.get<Array<IDocument>>(`/users`).subscribe(
      (users) => {
        this.setState({
          ...this.currentState,
          data: { ...this.currentState.data, totalUsers: users.length },
          loaded: true,
          loading: false,
        });
      },
      (err) => {
        this.setState({
          ...this.currentState,
          error: true,
          loading: false,
          loaded: false,
        });
      },
    );
  }
}

export interface DashboardState {
  loaded: boolean;
  loading: boolean;
  error: boolean;
  data: { };
}
