import { Injectable } from '@angular/core';
import { INotyfPosition, Notyf } from 'notyf';
import 'notyf/notyf.min.css';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor() { }

  notify(
    type: string,
    message: string,
    duration?: number,
    dismissible?: boolean,
    position?: INotyfPosition,
    ): void {
    const notyf = new Notyf({
      types: [
        {
          type: 'warning',
          background: 'orange',
          icon: {
            className: 'material-icons',
            tagName: 'i',
            text: 'warning',
            color: 'white'
          }
        },
        {
          type: 'info',
          background: 'blue',
          icon: false
        }
      ]
    });
    notyf.open({
      type,
      message,
      duration: duration ? duration : 4000,
      position: position ? position : { x: 'right', y: 'bottom' },
      dismissible: dismissible ? dismissible : true,
    });
  }
}
