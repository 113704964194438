import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';

import { Observable } from 'rxjs';
import { Store } from '../store/store';
import { IAnnotationState } from './annotation.service';
import { environment } from 'src/environments/environment';
import { IDocumentPage } from './document-pages.service';

const DEFAULT_STATE: DocumentState = {
  loaded: false,
  loading: false,
  error: false,
  currentPage: 0,
  data: [],
};

@Injectable({
  providedIn: 'root',
})
export class DocumentService extends Store<DocumentState> {
  currentState: DocumentState;

  constructor(http: HttpClient) {
    super(http, DEFAULT_STATE);
    this.getState().subscribe((state) => (this.currentState = state));
  }

  saveDocument(document: IDocument): void {
    this.setState({
      ...this.currentState,
      data: [...this.currentState.data, document],
    });
  }

  deleteDocumentFromState(document: IDocument): void {
    const previousState = [...this.currentState.data].filter(doc => doc._id !== document._id);
    this.setState({
      ...this.currentState,
      data: [...previousState]
    });
  }

  loadDocuments(): void {
    if (this.currentState.loaded || this.currentState.loading) {
      return;
    }
    const currentPage = this.currentState.currentPage + 1;
    this.setState({
      ...this.currentState,
      loading: true,
      currentPage,
    });
    this.get<Array<IDocument>>(`/documents`).subscribe(
      (documents) => {
        // const loaded = documents.length === 0;
        this.setState({
          ...this.currentState,
          data: [...this.currentState.data, ...documents],
          loaded: true,
          loading: false,
        });
      },
      (err) => {
        this.setState({
          ...this.currentState,
          error: true,
          loading: false,
          loaded: false,
        });
      },
    );
  }

  addDocument(payload: IDocument): Observable<IDocument> {
    const data = new FormData();
    data.append('idDoc', payload.idDocument);
    data.append('document', payload.file);
    return this.post<IDocument>(data, '/documents');
  }

  verifyDocumentAnnotation(payload: IDocument): Observable<IDocumentAnnotationsVerification> {
    const data = new FormData();
    data.append('lab_report', payload.file);
    return this.post<IDocumentAnnotationsVerification>(data, '/verify_annotation', environment.labReportApi);
  }

  updateDocument(payload: IDocument, id: string): Observable<IDocument> {
    return this.put<IDocument>(payload, `/documents/${id}`);
  }

  // getDocuments(): Observable<Array<IDocument>> {
  //   return this.get<Array<IDocument>>('/documents');
  // }

  deleteDocument(id: string): Observable<IDocument> {
    return this.delete<IDocument>(`/documents/${id}`);
  }

  getDocumentPages(id: string): Observable<IDocumentPage> {
    return this.get<IDocumentPage>(`/documents/${id}/pages`);
  }

  async readFile(Key: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const bucket = new S3({
        region: environment.s3Region,
        accessKeyId: environment.s3_access_id,
        secretAccessKey: environment.s3_secret,
      });
      bucket.getObject(
        {
          Bucket: environment.s3_bucket,
          Key,
        },
        (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        },
      );
    });
  }
}

export interface IDocument {
  url?: {
    Location: string;
    ETag: string;
    Key: string;
  };
  idDocument: string;
  uploadedAt?: any;
  imageUrl?: {
    Location: string;
    ETag: string;
    Key: string;
  };
  id?: any;
  _id?: any;
  file?: File;
  annotations?: IAnnotationState;
  pages?: Array<IDocumentPage>;
}

export interface DocumentState {
  loaded: boolean;
  loading: boolean;
  error: boolean;
  currentPage: number;
  data: Array<IDocument>;
}

export interface IDocumentAnnotationsVerification {
  annotated?: boolean;
  template?: {
    id: string;
    name: string;
  }
}
