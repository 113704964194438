import { Injectable } from '@angular/core';
import { Store } from '../store/store';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AnnotationService extends Store<{}> {
  constructor(private httpClient: HttpClient) {
    super(httpClient, {});
  }

  deleteAnnotationState(id: string): Observable<any> {
    return this.delete(`/annotations/${id}`);
  }

  saveAnnotationState(payload: IAnnotationState): Observable<IAnnotationState> {
    return this.post(payload, '/annotations');
  }

  reloadAnnotation(): Observable<any> {
    const resourceURL = `${environment.labReportApi}/reload_annotation`;
    return this.httpClient.get(resourceURL);
  }

  parseToJson(fileS3Key: string): Observable<any> {
    const payload = new FormData();
    payload.append('Key', fileS3Key);
    return this.post(payload, '/documents/parsetotext');
  }

  parsePdfToHtml(fileS3: string): Observable<any> {
    return this.post(
      { fileS3, zoom: 2 },
      '',
      environment.pdf2HtmlApi
    );
  }
}

export interface IAnnotationState {
  html: string;
  data: Array<IAnnotation>;
  flattenHtmlContent: Array<IElementHtml>;
  hasStructuredTable: boolean;
  columnsTable?: Array<ITableColumnAnnotation>;
  type: number;
  document: any;
  id?: string;
  _id?: string;
}

export interface IElementHtml {
  index: number;
  length: number;
  text: string;
  parents: any[];
  node: any;
}

export interface IAnnotation {
  key: string;
  previousCtx: string;
  nextCtx: string;
  indexElement: number;
  indexHtml: number;
  indexesHtml: Array<number>;
  idSpan: string;
  idsSpan?: Array<string>;
  value: string;
  categoryKey: string;
}

interface ITableColumnAnnotation {
  key: string;
  value: string;
}
