import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { HttpClientBase } from './../utils/http-client.base';

export class Store<T> extends HttpClientBase {
  // Using a behavior subject so we can provide a default value
  private state$: BehaviorSubject<T>;

  protected constructor(httpClient: HttpClient, initialState: T) {
    super(httpClient);
    // Setting the default state
    this.state$ = new BehaviorSubject(initialState);
  }

  getState(): Observable<T> {
    return this.state$.asObservable();
  }

  setState(nextState: T): void {
    this.state$.next(nextState);
  }
}
