import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const TOKEN_KEY = 'smart-rfc:token';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isAuth = false;
  authenticatedUser;
  constructor(private http: HttpClient) {}
   
 login(payload: { username: string; password: string }): Promise<boolean> {
   return new Promise((resolve, reject) => {
    this.http.post(`${environment.apiUrl}/users/auth/signin`, payload).subscribe(res => {
      this.authenticatedUser = res;
      if (this.authenticatedUser.valid) {
        this.isAuth = true;
        this.store(this.authenticatedUser.user);
        localStorage.setItem(TOKEN_KEY, this.authenticatedUser.accessToken);
        resolve(true);
      } else {
          resolve(false);
        }
    });
   });
 }

 private store(user): void {
   localStorage.setItem('user', JSON.stringify(user));
 }

 getAuthenticatedUser(): any {
   return JSON.parse(localStorage.getItem('user'));
 }


  logout(): void {
    this.isAuth = false;
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem('user');
    window.location.reload();
  }

  isAuthenticated(): boolean {
    return !!localStorage.getItem(TOKEN_KEY);
  }
}
