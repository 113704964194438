import { Component, OnInit, ViewChild } from '@angular/core';
import { DocumentService, IDocument } from '../../providers/document.service';
import {
  AnnotationService,
  IElementHtml,
  IAnnotation,
  IAnnotationState,
} from '../../providers/annotation.service';
import { IKey, KeyService } from '../../providers/key.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { RegularModalComponent } from '../../components/regular-modal/regular-modal.component';
import { DocumentPagesService } from '../../providers/document-pages.service';
import { LocalStorageService } from '../../providers/local-storage.service';
import { ToastService } from '../../providers/toast.service';

interface ITableColumnAnnotation {
  key: string;
  value: string;
}

declare var rangy: any;
declare var document: any;
const KEY_COLORS = ['blue', 'red', 'green', 'indigo', 'yellow', 'pink', 'gray'];

@Component({
  selector: 'app-annotation',
  templateUrl: './annotation.component.html',
  styleUrls: ['./annotation.component.scss'],
})
export class AnnotationComponent implements OnInit {
  pdfSrc: any;
  fileText = '';
  htmlContent: any;
  flattenHtmlContent: Array<IElementHtml> = [];
  documentID = '';
  document: IDocument;
  tableColumnAnnotations: Array<ITableColumnAnnotation> = [];
  showTableColumnAnnotationsModal = false;
  tableColumnAnnotationsModalParams = {
    title: 'Column Table Annotation',
    keys: [],
    columnsTable: [],
  };
  testInformationKeys: Array<IKey> = [];
  columnsTable = null;

  keys$: Observable<
    Array<{
      label: string;
      color: string;
      category: string;
    }>
  >;
  currentDocument$: Observable<IDocument>;
  selectedKey: {
    label: string;
    color: string;
    category: string;
  };
  previousSelectedKey = null;

  annotations: Array<IAnnotation> = [];
  hasStructuredTable = null;
  hasStructuredTableError = false;

  savePending = false;
  isAnnotationLoading = false;

  options = [
    { value: null, label: 'Have you finished ?' },
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ];

  @ViewChild('modal') modal: RegularModalComponent;

  currentPage = 0;

  constructor(
    private documentSvc: DocumentService,
    private keySvc: KeyService,
    private route: ActivatedRoute,
    private annotationSvc: AnnotationService,
    private documentPageSvc: DocumentPagesService,
    private localStorageService: LocalStorageService,
    private toastService: ToastService,
  ) {
    this.documentSvc.loadDocuments();
    this.keySvc.loadKeys();
    this.keys$ = this.keySvc.getState().pipe(
      map((state) => {
        const data = state.data;
        return data.map((key) => {
          const color =
            KEY_COLORS[Math.floor(Math.random() * KEY_COLORS.length)];
          return {
            label: key.label,
            color,
            category: key.category,
          };
        });
      })
    );
  }

  navigateBetweenDocumentPage(to: 'previous' | 'next') {
    switch (to) {
      case 'previous':
        if (this.currentPage > 0) {
          this.currentPage -= 1;
          this.ngOnInit();
        }
        break;
      case 'next':
        if (this.currentPage < this.document.pages.length) {
          this.currentPage += 1;
          this.ngOnInit();
        }
        break;
      default:
        break;
    }
  }

  toggleTableColumnAnnotationsModal() {
    this.showTableColumnAnnotationsModal =
      !this.showTableColumnAnnotationsModal;
  }

  getTestInformationKeys() {
    this.keySvc.getKeysOfCategory('test_information').subscribe(
      (data) => {
        this.testInformationKeys = data;
        this.tableColumnAnnotationsModalParams.keys = this.testInformationKeys;
      },
      (error) => console.log(error)
    );
  }

  mergeByProperty(target, source, prop): void {
    source.forEach((sourceElement) => {
      let targetElement = target.find((targetElement) => {
        return sourceElement[prop] === targetElement[prop];
      });
      targetElement
        ? Object.assign(targetElement, sourceElement)
        : target.push(sourceElement);
    });
  }

  updateModalState($event) {
    this.showTableColumnAnnotationsModal = $event.modalState;
  }

  saveTableColumnAnnotations($event) {
    this.columnsTable = $event.annotatedColumns;
  }

  ngOnInit(): void {
    this.isAnnotationLoading = true;
    this.route.params.subscribe((params) => {
      const documentID = params.document;
      this.documentID = documentID;
      this.currentDocument$ = this.documentSvc.getState().pipe(
        map((state) => {
          const data = state.data;
          return data.filter((doc) => doc._id === documentID)[0];
        })
      );
      this.currentDocument$.subscribe((doc) => {
        this.document = doc;
        if (!doc) {
          return;
        }
        if (
          doc.pages[this.currentPage]?.html.length === 0 ||
          !doc.annotations
        ) {
          this.hasStructuredTable = null;
          this.documentSvc
            .readFile(this.document.pages[this.currentPage]?.url?.Key)
            .then((data: any) => {
              this.pdfSrc = data.Body;
            });
          this.annotationSvc
            .parsePdfToHtml(this.document.pages[this.currentPage]?.url?.Key)
            .subscribe((res: any) => {
              if (!document.getElementById('pdf-extract')) {
                return;
              }
              this.htmlContent = this.parseHTML(res.html);
              this.fileText = res.html;
              const html = this.parseHTML(res.html);
              const styles = html.getElementsByTagName('style');
              for (const style of styles) {
                document.getElementById('pdf-extract').appendChild(style);
              }
              const pageContainer = html.getElementById('page-container');
              if (document.getElementById('page-container')) {
                document.getElementById('page-container').remove();
              }
              document.getElementById('pdf-extract').appendChild(pageContainer);
              pageContainer.childNodes.forEach((child) => {
                this.flattenHtmlContent = [
                  ...this.flattenHtmlContent,
                  ...this.flattenHtml(child),
                ];
              });
              this.flattenHtmlContent = this.flattenHtmlContent.filter(
                (el) => el.text && el.text.replace(/\s/g, '').length
              );
              this.isAnnotationLoading = false;
              this.flattenHtmlContent = [...this.removeFooterFromFlattenHtml()];
            });
        } else {
          if (document.getElementById('content')) {
            document.getElementById('content').innerHTML =
              this.document.pages[this.currentPage]?.html;
          }
          this.htmlContent = this.parseHTML(
            this.document?.pages[this.currentPage]?.html
          );
          this.hasStructuredTable = doc.annotations.hasStructuredTable;
          this.columnsTable = doc.annotations.columnsTable;
          if (
            this.modal?.parameters.columnsTable.length === 0 &&
            doc.annotations.columnsTable?.length > 0
          ) {
            this.tableColumnAnnotationsModalParams.columnsTable =
              this.columnsTable;
            this.modal.parameters.columnsTable = doc.annotations.columnsTable;
            this.modal.ngOnInit();
          } else {
            this.tableColumnAnnotationsModalParams.columnsTable =
              this.columnsTable;
          }
          this.flattenHtmlContent = doc.annotations.flattenHtmlContent;
          this.annotations = doc.annotations.data;
          this.annotations.forEach((annotation) => {
            if (annotation?.idsSpan.length) {
              annotation?.idsSpan.forEach((id) => {
                const span = document.getElementById(id);
                if (span) {
                  span.onclick = () => {
                    this.document.annotations.data = [
                      ...this.document.annotations.data.filter(
                        (annot) => annot.indexElement != annotation.indexElement
                      ),
                    ];
                    const annotations = [];
                    if (
                      (annotation.indexHtml === annotation.indexHtml ||
                        annotation.indexesHtml.includes(
                          annotation.indexHtml
                        )) &&
                      annotation.key === annotation.key
                    ) {
                    } else {
                      annotations.push(annotation);
                    }
                    this.annotations = annotations;
                    span.className =
                      span.classList.value.split('select-none')[0];
                    span.onclick = () => {};
                  };
                }
              });
            }
          });
          this.isAnnotationLoading = false;
          const isAnnotationSaved = this.localStorageService.getItem('save-annotation');
          if (isAnnotationSaved !== null && isAnnotationSaved === 'success') {
            this.toastService.notify('success', 'Annotations successfully saved.');
            this.localStorageService.deleteItem('save-annotation');
          }
        }
      });
    });
  }

  parseHTML(markup): any {
    if (markup.toLowerCase().trim().indexOf('<!doctype') === 0) {
      const doc = document.implementation.createHTMLDocument('');
      doc.documentElement.innerHTML = markup;
      return doc;
    } else if ('content' in document.createElement('template')) {
      // Template tag exists!
      const el = document.createElement('template');
      el.innerHTML = markup;
      return el.content;
    } else {
      // Template tag doesn't exist!
      const docfrag = document.createDocumentFragment();
      const el = document.createElement('body');
      el.innerHTML = markup;
      for (const i = 0; 0 < el.childNodes.length; ) {
        docfrag.appendChild(el.childNodes[i]);
      }
      return docfrag;
    }
  }

  textSelected(oField): void {
    if (!this.htmlContent || !this.selectedKey) {
      return;
    }
    let sel: any;
    let range: any;
    let parentEl: any;
    if (window.getSelection) {
      sel = rangy.getSelection();
      const text = window.getSelection().toString();
      if (!text) {
        return;
      }
      if (sel.rangeCount) {
        parentEl = sel.getRangeAt(0).commonAncestorContainer;
        if (parentEl.nodeType !== 1) {
          parentEl = parentEl.parentNode;
        }
        if (parentEl.classList.contains('key:baamtu')) {
          return;
        }
        range = sel.getRangeAt(0);
        /** expand current selection and get text context */
        range.expand('word');
        const wordRange = range.cloneRange();
        wordRange.collapse(true);
        wordRange.moveStart('word', -8);
        const previousCtx = wordRange.text().replace('\n', ' ');
        wordRange.collapseToPoint(range.endContainer, range.endOffset);
        wordRange.moveEnd('word', 8);
        const nextCtx = wordRange.text().replace('\n', ' ');
        wordRange.detach();
        /** end selected text context */
        // get current selected element
        const textElementNode = this.getTextElementNode(
          text,
          previousCtx,
          nextCtx
        );
        if (
          parentEl.firstChild?.nodeType !== 3 &&
          !parentEl.firstChild?.data?.includes(text)
        ) {
          if (!textElementNode) {
            return;
          }
        }
        range.deleteContents();
        const newNodeElement = document.createElement('SPAN');
        newNodeElement.innerHTML = `${text}`;
        newNodeElement.setAttribute('title', this.selectedKey.label);
        const idSpan = `${this.selectedKey.label.replace(
          /\s/g,
          '_'
        )}${new Date().getTime()}`;
        newNodeElement.setAttribute('id', idSpan);
        const newNodeClass = textElementNode?.node?.classList?.value;
        const parentElClass = parentEl.classList.value;
        const classes = [
          'select-none',
          'key:baamtu',
          'font-semibold',
          'inline-block',
          'p-2',
          'rounded',
          'last:mr-0',
          'mr-1',
          `text-${this.selectedKey.color}-600`,
          `bg-${this.selectedKey.color}-200`,
        ];
        if (!new RegExp('ff[0-9]').test(parentElClass)) {
          newNodeElement.className = `${newNodeClass} ${classes.join(' ')}`;
        } else {
          newNodeElement.className = `${classes.join(' ')}`;
        }
        const key = String(this.selectedKey.label);
        newNodeElement.onclick = () => {
          const annotations = [];
          let annotationToDelete: IAnnotation;
          this.annotations.forEach((annotation) => {
            if (
              (annotation.indexHtml === textElementNode?.index ||
                annotation.indexesHtml.includes(textElementNode?.index)) &&
              annotation.key === key
            ) {
              annotationToDelete = annotation;
            } else {
              annotations.push(annotation);
            }
          });
          this.annotations = annotations;
          console.log(this.annotations);
          classes.forEach((className) => {
            if (annotationToDelete?.idsSpan.length) {
              annotationToDelete.idsSpan.forEach((id) => {
                if (document.getElementById(id)) {
                  document.getElementById(id).classList.remove(className);
                }
              });
            }
            if (newNodeElement.classList.length && !annotationToDelete) {
              newNodeElement.classList.remove(className);
            }
          });
          if (annotationToDelete?.idsSpan.length) {
            annotationToDelete.idsSpan.forEach((id) => {
              if (document.getElementById(id)) {
                document.getElementById(id).removeAttribute('id');
              }
            });
          }
          newNodeElement.onclick = () => {};
        };
        range.insertNode(newNodeElement);
        const prevElSibling = newNodeElement.previousElementSibling;
        if (
          prevElSibling &&
          prevElSibling.classList.value.includes(parentElClass)
        ) {
          prevElSibling.append(newNodeElement);
        }
        this.saveElementContext(textElementNode, text, idSpan);
      } else {
        alert('select again please');
      }
    } else {
      alert('select again please');
    }
    this.clear();
  }

  saveElementContext(el: IElementHtml, text: string, idSpan: string): void {
    let indexCurrentElmt;
    if (el === null || el === undefined) {
      indexCurrentElmt = this.flattenHtmlContent.findIndex((item) =>
        item.text.includes(text)
      );
    } else {
      indexCurrentElmt = this.flattenHtmlContent.findIndex(
        (item) => item.index === el?.index
      );
    }
    const currentElementText =
      el?.text.trim() ||
      (this.flattenHtmlContent[indexCurrentElmt] &&
        this.flattenHtmlContent[indexCurrentElmt].text?.trim());
    let previousText = currentElementText.trim().split(text.trim())[0].trim();
    let nextText = currentElementText.trim().split(text.trim())[1].trim();
    const prevCtxIndex =
      indexCurrentElmt === 0 ? indexCurrentElmt : indexCurrentElmt - 1;
    const nextCtxIndex =
      this.flattenHtmlContent.length === indexCurrentElmt + 1
        ? indexCurrentElmt
        : indexCurrentElmt + 1;
    if (!previousText && this.flattenHtmlContent[prevCtxIndex]) {
      const _text = this.flattenHtmlContent[prevCtxIndex].text.trim();
      const parts = _text.split(text.trim());
      if (parts.length > 1) {
        previousText = parts[0].trim();
        nextText = parts[1].trim();
      } else {
        previousText = parts[0].trim();
      }
    }
    if (!nextText && this.flattenHtmlContent[nextCtxIndex]) {
      nextText = this.flattenHtmlContent[nextCtxIndex].text.trim();
    }
    let previousCtx = '';
    let previousCtxEnd = false;
    let previousSpaceCount = 0;
    if (previousText) {
      for (let i = previousText.length - 1; i > -1 && !previousCtxEnd; i--) {
        if (i !== previousText.length - 1 && previousText[i] === ':') {
          previousCtxEnd = true;
        } else {
          if (previousText[i].match(/\s/g)) {
            previousSpaceCount++;
          }
          if (previousSpaceCount === 2) {
            previousCtxEnd = true;
          } else {
            previousCtx = previousText[i] + previousCtx;
          }
        }
      }
    }
    let nextCtx = '';
    let nextCtxEnd = false;
    let nextSpaceCount = 0;
    if (nextText) {
      for (let i = 0; i < nextText.length && !nextCtxEnd; i++) {
        if (i !== nextText.length - 1 && nextText[i] === ':') {
          nextCtxEnd = true;
        } else {
          if (nextText[i].match(/\s/g)) {
            nextSpaceCount++;
          }
          if (nextSpaceCount === 2) {
            nextCtxEnd = true;
          } else {
            nextCtx += nextText[i];
          }
        }
      }
    }
    const index = el
      ? el.index
      : indexCurrentElmt
      ? this.flattenHtmlContent[indexCurrentElmt].index
      : Math.ceil(Math.random() * 100);
    const currentAnnotation = {
      indexElement: indexCurrentElmt,
      indexHtml: index,
      key: this.selectedKey.label,
      previousCtx,
      nextCtx,
      idSpan,
      value: text,
      categoryKey: this.selectedKey.category,
      idsSpan: [idSpan],
      indexesHtml: [index],
    };
    const previousAnnotation = this.annotations.find((annotation) => {
      return (
        annotation.value.includes(currentAnnotation.previousCtx) &&
        annotation.key === this.selectedKey.label
      );
    });
    const nextAnnotation = this.annotations.find((annotation) => {
      return (
        annotation.value.includes(currentAnnotation.nextCtx) &&
        annotation.key === this.selectedKey.label
      );
    });
    if (previousAnnotation) {
      delete this.annotations[this.annotations.indexOf(previousAnnotation)];
      currentAnnotation.idsSpan = [
        ...previousAnnotation.idsSpan,
        ...currentAnnotation.idsSpan,
      ];
      currentAnnotation.indexesHtml = [
        ...previousAnnotation.indexesHtml,
        ...currentAnnotation.indexesHtml,
      ];
      currentAnnotation.previousCtx = previousAnnotation.previousCtx;
      currentAnnotation.value =
        previousAnnotation.value + '|' + currentAnnotation.value;
    }
    if (nextAnnotation) {
      delete this.annotations[this.annotations.indexOf(nextAnnotation)];
      currentAnnotation.idsSpan = [
        ...nextAnnotation.idsSpan,
        ...currentAnnotation.idsSpan,
      ];
      currentAnnotation.indexesHtml = [
        ...nextAnnotation.indexesHtml,
        ...currentAnnotation.indexesHtml,
      ];
      currentAnnotation.nextCtx = nextAnnotation.nextCtx;
      currentAnnotation.value =
        currentAnnotation.value + '|' + nextAnnotation.value;
    }
    this.annotations = this.annotations.filter((annotation) => {
      return !!annotation;
    });
    if (currentAnnotation.previousCtx === '') {
      let text = this.flattenHtmlContent[indexCurrentElmt].text;
      let textSplitted = text.split(':');
      if (text.includes(':') && textSplitted[0] != '') {
        currentAnnotation.previousCtx = textSplitted[0];
      }
    }
    this.annotations.push(currentAnnotation);
  }

  saveAnnotations(): void {
    if (
      this.hasStructuredTable === null ||
      this.hasStructuredTable === undefined
    ) {
      this.hasStructuredTableError = true;
      return;
    }
    if (this.savePending) {
      return;
    }
    this.savePending = true;
    if (this.document.annotations !== undefined) {
      this.mergeByProperty(
        this.document.annotations.data,
        this.annotations,
        'value'
      );
    }
    const annotationState: IAnnotationState = {
      html: document.getElementById('pdf-extract').outerHTML,
      data:
        this.document.annotations === undefined
          ? this.annotations
          : this.document.annotations.data,
      flattenHtmlContent: this.flattenHtmlContent,
      document: this.documentID,
      columnsTable: this.columnsTable,
      hasStructuredTable:
        this.document.annotations?.hasStructuredTable ||
        this.hasStructuredTable === 'true',
      type: this.hasStructuredTable === 'true' ? 2 : 1,
    };
    // console.log(annotationState);
    // return ;
    if (this.document.annotations) {
      this.annotationSvc
        .deleteAnnotationState(this.document.annotations._id)
        .subscribe(
          () => {
            this.annotationSvc.saveAnnotationState(annotationState).subscribe(
              (res) => {
                this.saveDocumentPageHtml(
                  this.document.pages[this.currentPage]._id,
                  { html: annotationState.html }
                ).subscribe(
                  (res) => {
                    this.documentPageSvc.updateDocumentPageFromState(
                      this.document.pages[this.currentPage]
                    );
                    this.localStorageService.storeItem('save-annotation', 'success');
                    this.annotationSvc.reloadAnnotation().subscribe(
                      res => {
                        this.savePending = false;
                        window.location.reload();
                        console.log('OK ', res);
                      },
                      error => {
                        this.savePending = false;
                        window.location.reload();
                        console.log('An error has occured !', error);
                      },
                      () => {
                        this.savePending = false;
                        window.location.reload();
                      }
                    );
                  },
                  (error) => {
                    this.savePending = false;
                    alert('unable to save the annotation.');
                    console.log(error);
                  }
                );
                // this.location.back();
                // this.router.navigateByUrl('/dashboard/documents');
                // this.router.navigateByUrl('annotation/' + this.documentID);
              },
              (err) => {
                alert('unable to save the annotation.');
                this.savePending = false;
              }
            );
          },
          (err) => {
            alert('unable to save the annotation.');
            this.savePending = false;
          }
        );
    } else {
      this.annotationSvc.saveAnnotationState(annotationState).subscribe(
        (res) => {
          this.saveDocumentPageHtml(this.document.pages[this.currentPage]._id, {
            html: annotationState.html,
          }).subscribe(
            (res) => {
              this.savePending = false;
              this.documentPageSvc.updateDocumentPageFromState(
                this.document.pages[this.currentPage]
              );
              window.location.reload();
            },
            (error) => {
              this.savePending = false;
              alert('unable to save the annotation.');
              console.log(error);
            }
          );
          // window.location.reload();
          // this.location.back();
        },
        (err) => {
          alert('unable to save the annotation.');
          this.savePending = false;
        }
      );
    }
  }

  saveDocumentPageHtml(id: string, payload: { html: string }) {
    return this.documentPageSvc.updateDocumentPage(id, payload);
  }

  slideToDocument(direction: 'next' | 'prev'): Observable<IDocument> {
    return this.documentSvc.getState().pipe(
      map((state) => {
        const currentIndex = state.data.findIndex(
          (doc) => doc._id === this.documentID
        );
        if (currentIndex === -1) {
          return;
        }
        return direction === 'next'
          ? state.data[currentIndex + 1]
          : state.data[currentIndex - 1];
      })
    );
  }

  compareCtx(text1: string, text2: string): boolean {
    text1 = text1.trim().replace(/\n/g, ' ');
    text2 = text2.trim().replace(/\n/g, ' ');
    if (text1.length >= text2.length) {
      return text1.includes(text2);
    }
    return text2.includes(text1);
  }

  getTextElementNode(text: string, previousCtx, nextCtx): IElementHtml {
    if (!text) {
      return;
    }
    let foundNode: IElementHtml;
    this.flattenHtmlContent.forEach((el, index) => {
      if (el.text.trim().indexOf(text.trim()) !== -1) {
        const previousEl = this.flattenHtmlContent[index - 1];
        const nextEl = this.flattenHtmlContent[index + 1];
        const currentElText = el.text;
        const leftText = currentElText.split(text)[0];
        const rigthText = currentElText.split(text)[1];
        if (previousEl || nextEl) {
          if (
            this.compareCtx(`${previousEl?.text} ${leftText}`, previousCtx) &&
            this.compareCtx(`${rigthText} ${nextEl?.text}`, nextCtx)
          ) {
            foundNode = el;
            return foundNode;
          }
        }
      }
    });
    return foundNode;
  }

  flattenHtml(node, flat = [], tagsList = []): IElementHtml[] {
    // Add the current tag
    tagsList.push(node);
    // Check if there are only textnode childs
    if (this.hasOnlyNodesText(node)) {
      // Calculate the node index
      const index =
        flat[flat.length - 1] === undefined
          ? 0
          : flat[flat.length - 1].index + flat[flat.length - 1].length;

      // Push the node in the array
      flat.push({
        index,
        length: node.length ?? 1,
        text: node.textContent ?? '',
        parents: [...tagsList],
        node,
      });
    } else {
      // Call the function recursively on each child
      node.childNodes.forEach((child) => {
        flat = this.flattenHtml(child, flat, tagsList);
      });
    }
    // Remove the current tag
    tagsList.splice(tagsList.indexOf(node), 1);
    return flat;
  }

  hasOnlyNodesText(node: Element): boolean {
    let res = true;
    node.childNodes.forEach((child) => {
      if (child.nodeName !== 'SPAN' && child.nodeName !== '#text') {
        res = false;
      }
    });
    return res;
  }

  clear(): void {
    if (window.getSelection) {
      if (window.getSelection().empty) {
        // Chrome
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {
        // Firefox
        window.getSelection().removeAllRanges();
      }
    } else if (document.selection) {
      // IE?
      document.selection.empty();
    }
  }

  onSelectedKey($event, key): void {
    if (this.previousSelectedKey) {
      this.previousSelectedKey.classList.remove('selected-key');
    }
    this.selectedKey = key;
    $event.target.classList.add('selected-key');
    this.fadeIn($event.target, 1000);
    this.previousSelectedKey = $event.target;
  }

  fadeIn(el, time): void {
    el.style.opacity = 0;

    let last = +new Date();
    const tick = () => {
      el.style.opacity = +el.style.opacity + (+new Date() - last) / time;
      last = +new Date();

      if (+el.style.opacity < 1) {
        // tslint:disable-next-line: no-unused-expression
        (window.requestAnimationFrame && requestAnimationFrame(tick)) ||
          setTimeout(tick, 16);
      }
    };

    tick();
  }

  removeFooterFromFlattenHtml() {
    const footer = `1A Olabode street off M/M international airport
    road by 7 and 8 Bus stop, Ajao Estate, Lagos.
    Tel: 0906 254 7166, 081 655 90816
    Email: info@eromhealthcare.net,
    eromhealthcare2@gmail.com
    Website: www.eromhealthcare.net`;
    return this.flattenHtmlContent.filter(
      (element) => !footer.trim().includes(element.text.trim())
    );
  }
}
