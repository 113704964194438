import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from 'src/app/core/store/store';
import { Observable } from 'rxjs';
import { IKey } from './key.service';

const PAGE_SIZE = 8;
const DEFAULT_STATE: TableColumnAnnotationState = {
  loaded: false,
  loading: false,
  error: false,
  currentPage: 0,
  data: [],
};

@Injectable({
  providedIn: 'root',
})
export class TableColumnAnnotationService extends Store<TableColumnAnnotationState> {
  currentState: TableColumnAnnotationState;
  constructor(http: HttpClient) {
    super(http, DEFAULT_STATE);
    this.getState().subscribe((state) => (this.currentState = state));
  }

  saveTableColumnAnnotation(tableColumnAnnotation: ITableColumnAnnotation): void {
    this.setState({
      ...this.currentState,
      data: [...this.currentState.data, tableColumnAnnotation],
    });
  }

  updateTableColumnAnnotationFromState(tableColumnAnnotation: ITableColumnAnnotation): void {
    const previousState = [...this.currentState.data].filter(vTableColumnAnnotation => vTableColumnAnnotation._id !== tableColumnAnnotation._id);
    this.setState({
      ...this.currentState,
      data: [...previousState, tableColumnAnnotation]
    });
  }

  deleteTableColumnAnnotationFromState(tableColumnAnnotation: ITableColumnAnnotation): void {
    const previousState = [...this.currentState.data].filter(vTableColumnAnnotation => vTableColumnAnnotation._id !== tableColumnAnnotation._id);
    this.setState({
      ...this.currentState,
      data: [...previousState]
    });
  }

  loadTableColumnAnnotations(): void {
    if (this.currentState.loaded || this.currentState.loading) {
      return;
    }
    const currentPage = this.currentState.currentPage + 1;
    this.setState({
      ...this.currentState,
      loading: true,
      currentPage,
    });
    this.get<Array<ITableColumnAnnotation>>(`/table-column-annotations`).subscribe(
      (tableColumnAnnotations) => {
        this.setState({
          ...this.currentState,
          data: [...this.currentState.data, ...tableColumnAnnotations],
          loaded: true,
          loading: false,
        });
      },
      (err) => {
        this.setState({
          ...this.currentState,
          error: true,
          loading: false,
          loaded: false,
        });
      },
    );
  }

  getAll(): Observable<ITableColumnAnnotation[]> {
    return this.get<ITableColumnAnnotation[]>('/table-column-annotations');
  }
  
  addTableColumnAnnotation(payload: ITableColumnAnnotation): Observable<ITableColumnAnnotation> {
    return this.post<ITableColumnAnnotation>(payload, '/table-column-annotations');
  }

  updateTableColumnAnnotation(payload: ITableColumnAnnotation, id: string): Observable<ITableColumnAnnotation> {
    return this.put<ITableColumnAnnotation>(payload, `/table-column-annotations/${id}`);
  }

  deleteTableColumnAnnotation(id: string): Observable<ITableColumnAnnotation> {
    return this.delete<ITableColumnAnnotation>(`/table-column-annotations/${id}`);
  }

}

export interface ITableColumnAnnotation {
  value: string;
  key: string;
  createdAt?: Date;
  updatedAt?: Date;
  _id?: any;
  id?: any;
}

export interface TableColumnAnnotationState {
  loaded: boolean;
  loading: boolean;
  error: boolean;
  currentPage: number;
  data: Array<ITableColumnAnnotation>;
}
