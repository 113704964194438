import { Component, OnInit } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { KeyService, IKey } from '../../providers/key.service';
import { Validators, FormGroup } from '@angular/forms';
import { ToastService } from '../../providers/toast.service';
import { AuthService } from '../../providers/auth.service';

@Component({
  selector: 'app-key',
  templateUrl: './key.component.html',
  styleUrls: ['./key.component.sass'],
})
export class KeyComponent implements OnInit {
  formClass = 'bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4';
  inputWrapperClass = 'grid grid-cols-2 gap-4';
  actionsWrapperClass = 'flex items-center justify-between';
  inputs = [];
  formActions = [];
  submitLoading = false;
  resetForm$ = new Subject<IKey>();

  isCurrentAuthenticatedUserAdmin = false;
  
  keys$: Observable<Array<IKey>>;
  selectedKey: IKey;

  modalParameters = {
    title: '',
    message: '',
    operation: '',
    isOpen: false
  };

  formValue;
  constructor(private keyService: KeyService, private toastService: ToastService, private authService: AuthService) { }

  ngOnInit(): void {
    this.isCurrentAuthenticatedUserAdmin = this.authService.getAuthenticatedUser().role === 0 ? true : false;
    this.setForm();
    this.keyService.loadKeys();
    this.keys$ = this.keyService.getState().pipe(map((state) => state.data));
  }

  setForm(): void {
    this.formActions = [
      {
        type: 'button',
        id: 'submit',
        class:
          'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline',
        errorClass: 'bg-gray-500 text-white font-bold py-2 px-4 rounded',
        text: 'Save',
        validationRequired: true,
      },
    ];
    this.inputs = [
      {
        groupClass: 'col-span-12 mb-4',
        labelClass: 'block text-gray-700 text-sm font-bold mb-2',
        inputClass:
          'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
        inputClassSuccess: 'border-green-500',
        inputClassError: 'border-red-500',
        helperClass: '',
        type: 'text',
        form: 'input',
        id: 'label',
        value: '',
        label: 'Label',
        placeholder: 'Label',
        helperText: '',
        validators: [Validators.required],
      },
      {
        groupClass: 'col-span-12 mb-4',
        labelClass: 'block text-gray-700 text-sm font-bold mb-2',
        inputClass:
          'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
        inputClassSuccess: 'border-green-500',
        inputClassError: 'border-red-500',
        helperClass: '',
        type: '',
        form: 'select',
        id: 'category',
        value: '',
        label: 'Category',
        placeholder: '',
        helperText: '',
        validators: [Validators.required],
        selectOptionValues$: new BehaviorSubject([
          { label: 'Select a category', value: ''},
          { label: 'Personnal information', value: 'personnal_information'},
          { label: 'Test information', value: 'test_information'},
        ])
      },
      {
        groupClass: 'col-span-12 mb-4',
        labelClass: 'block text-gray-700 text-sm font-bold mb-2',
        inputClass:
          'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
        inputClassError: 'border-red-500',
        helperClass: '',
        type: 'text',
        form: 'textArea',
        id: 'description',
        value: '',
        label: 'Description',
        placeholder: 'Description',
        helperText: '',
        validators: [Validators.required],
      },
    ];
    if (this.isCurrentAuthenticatedUserAdmin) {
      this.inputs.push({
          groupClass: 'col-span-12 mb-4',
          labelClass: 'block text-gray-700 text-sm font-bold mb-2',
          inputClass:
            'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
          inputClassSuccess: 'border-green-500',
          inputClassError: 'border-red-500',
          helperClass: '',
          type: '',
          form: 'select',
          id: 'deletable',
          value: '',
          label: 'Deletable',
          placeholder: '',
          helperText: '',
          validators: [Validators.required],
          selectOptionValues$: new BehaviorSubject([
            { label: 'Can this key be deleted ?', value: ''},
            { label: 'Yes', value: 'yes'},
            { label: 'No', value: 'no'},
          ])
        });
    }
  }

  onActionPerformed($event: { id: string; form: FormGroup }): void {
    switch ($event.id) {
      case 'submit':
        if ($event.form.valid) {
          this.addKey($event.form.value);
        }
        break;
      case 'cancel':
        this.resetForm$.next();
        this.setForm();
        break;
      case 'update':
        if ($event.form.valid) {
          this.modalParameters.title = 'Update Key';
          this.modalParameters.message = 'Are you sure you want to update this key ?';
          this.modalParameters.operation = 'update';
          this.modalParameters.isOpen = true;
          this.formValue = $event.form.value;
        }
        break;
    }
  }

  addKey(payload: IKey): void {
    const keyPayload: IKey = {
      category: payload.category,
      label: payload.label,
      description: payload.description,
      deletable: this.isCurrentAuthenticatedUserAdmin ? payload.deletable : 'yes',
      createdAt: new Date()
    };
    this.submitLoading = true;
    this.keyService.addKey(keyPayload).subscribe((res) => {
      this.submitLoading = false;
      keyPayload._id = res._id;
      this.keyService.saveKey(keyPayload);
      this.resetForm$.next();
    });
    this.toastService.notify('success', 'Key successfully added.');
  }

  updateKey(payload: IKey): void {
    payload.createdAt = this.selectedKey.createdAt
    this.submitLoading = true;
    if(!this.isCurrentAuthenticatedUserAdmin) {
      payload.deletable = this.selectedKey.deletable;
    }
    this.keyService
      .updateKey(payload, this.selectedKey._id)
      .subscribe((res) => {
        this.formValue._id = res._id;
        this.submitLoading = false;
        this.formValue._id = res._id;
        this.keyService.updateKeyFromState(this.formValue);
        this.resetForm$.next();
        this.setForm();
      });
  }

  updateKeyForm(key: IKey): void {
    this.selectedKey = key;
    if (this.isCurrentAuthenticatedUserAdmin) {
      this.resetForm$.next({
        label: key.label,
        description: key.description,
        deletable: key.deletable,
        category: key.category
      });
    } else {
      this.resetForm$.next({
        label: key.label,
        description: key.description,
        category: key.category
      });
    }
    this.formActions = [
      {
        type: 'button',
        id: 'update',
        class:
          'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline',
        errorClass: 'bg-gray-500 text-white font-bold py-2 px-4 rounded',
        text: 'Update',
        validationRequired: true,
      },
      {
        type: 'button',
        id: 'cancel',
        class:
          'bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline',
        errorClass: 'bg-gray-500 text-white font-bold py-2 px-4 rounded',
        text: 'Cancel',
        validationRequired: false,
      },
    ];
    document.getElementById('form-title').scrollIntoView({behavior: 'smooth'});
  }

  deleteKey(key: IKey): void {
    this.modalParameters.title = 'Delete Key';
    this.modalParameters.message = 'Are you sure you want to delete this key ?';
    this.modalParameters.operation = 'delete';
    this.modalParameters.isOpen = true;
    if (key.deletable === 'no') {
      this.modalParameters.isOpen = false;
      this.toastService.notify('error', 'Sorry, this is an undeletable key, configured by an admin !');
      return ;
    }
    this.formValue = key;
  }

  manageOperation(emittedValue): void {
    if (emittedValue.proceed) {
      switch (emittedValue.operation) {
        case 'update':
          this.updateKey(this.formValue);
          this.toastService.notify('warning', 'Key successfully updated !');
          break;
        case 'delete':
          this.keyService.deleteKey(this.formValue._id).subscribe((res) => {
            this.keyService.deleteKeyFromState(this.formValue);
            this.toastService.notify('warning', 'Key successfully deleted !');
          });
          break;
      }
    }
    this.modalParameters.isOpen = false;
  }
}
